// in src/Menu.js
import * as React from 'react';
import {
    //DashboardMenuItem,
    useTranslate,
    // usePermissions,
} from 'react-admin';
import {useSelector} from 'react-redux';
import {Divider} from '@material-ui/core';
import SubMenu from "./SubMenu";
import {MenuItemLink} from 'react-admin';

import BusinessIcon from '@material-ui/icons/Business';

//import {canAccess, usePermissions} from "@react-admin/ra-rbac";
import {usePermissions} from "@react-admin/ra-rbac";

import {leftMenuConfig} from "../utils/config"
import {useEffect, useMemo, useState} from "react";
import {tableConfig} from "../tablesConfig/tableConfig";
import {canAccess2} from "../utils/canAccess2";

import OrderIcon from '@material-ui/icons/AttachMoney';
// import OrderIcon from '@material-ui/icons/Badge';

import ProductIcon from '@material-ui/icons/Collections';
// import ProductIcon from '@material-ui/icons/Inventory';
import CategoryIcon from '@material-ui/icons/Bookmarks';
import ClassifiersIcon from '@material-ui/icons/ViewList';
import StarIcon from '@material-ui/icons/Star';

import GroupIcon from '@material-ui/icons/Group';
import MenuIcon from '@material-ui/icons/Menu';
import {MyAiIcons} from "../icons/MyAiIcons";
//import {MyTranslateIcons} from "../icons/MyTranslateIcons";
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SettingsIcon from '@material-ui/icons/Settings';//іконка на Service
import UpdateIcon from '@material-ui/icons/Update';
import BuildIcon from '@material-ui/icons/Build';

import TranslateIcon from '@mui/icons-material/Translate';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import KeyIcon from '@mui/icons-material/Key';
import ArticleIcon from '@mui/icons-material/Article';
import PaymentsIcon from '@mui/icons-material/Payments';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import config from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
import DashboardIcon from "@material-ui/icons/Dashboard";
//import {Auth} from "aws-amplify";

// import {Menu} from "@react-admin/ra-rbac";

/*const canAccess2 = async (data) => {
    const ttt = await Auth.currentAuthenticatedUser();
    const groups = ttt.signInUserSession.accessToken.payload['cognito:groups'];
    console.log('groups=========>', groups, groups.indexOf('Admin'))
    if (groups.indexOf('Admin') < 0) return false;
    else return true;
};*/

function googleMerchant() {
    if (config.permission === 'swisslinker') {
        const getData = async () => {
            let url = config.apiUrl + 'api/react-admin/main/Config/1'
            try {
                const response = await httpClient(url);
                if (response.status >= 200 && response.status <= 300) {
                    const {json} = response
                    const {google_merchant_id, site_domain} = json || {}
                    localStorage.setItem('google_merchant_id', google_merchant_id ? google_merchant_id : '')
                    localStorage.setItem('site_domain', site_domain ? site_domain : '')
                } else {
                }
            } catch (err) {
            } finally {
            }
        }
        getData()
    }
}

export const MyMenu = ({dense = false}) => {
    const translate = useTranslate();
    //const {dense=false} = props || {}
    //const resources = useSelector(getResources);

    /*let st = {
        Promotion: false,
        Classifiers: false,
        Prompts: false,
        Admin: false,
        Config: false,
    };*/

    let st = useMemo(() => ({
        Promotion: false,
        Classifiers: false,
        Prompts: false,
        Admin: config.permission === 'zenit' ? true : false,
        Config: false,
    }), [])

    const [state, setState] = useState(st);
    const [menuReal, setMenuReal] = useState([]);
    const [countRequest, setCountRequest] = useState(0);

    //const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change
    // const classes = useStyles();

    const [state2, setState2] = useState({
        related_table: false,
    });

    useEffect(() => {
        googleMerchant()
    }, []);// eslint-disable-line

    function countClientRequest() {
        if (config.permission === 'zenit') {
            const getData = async () => {
                let url = config.apiUrl + 'api/account-request/unprocessed-requests-count/'
                try {
                    const response = await httpClient(url);
                    if (response.status >= 200 && response.status <= 300) {
                        const {json} = response
                        const {count} = json || {}
                        setCountRequest(count)
                    } else {
                        setCountRequest(0)
                    }
                } catch (err) {
                    setCountRequest(0)
                } finally {
                }
            }
            getData()
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            countClientRequest()
        }, 10000); // 10 секунд = 10000 мс
        countClientRequest()
        return () => clearInterval(interval); // Очищаем при размонтировании
    }, []);// eslint-disable-line

    useEffect(() => {
        let newSt = st
        leftMenuConfig.forEach((it) => {
            newSt[it.name] = false
        })
        setState(newSt)
        let newMenuReal = []
        leftMenuConfig.forEach(it => {
            let i = 0
            tableConfig.tablesList.filter(x => x.menu === it.name).forEach(item => {
                if (canAccess2({permissions, resource: item.table, action: 'list'})) {
                    i += 1
                }
            })
            if (i > 0) newMenuReal.push(it)
        })
        setMenuReal(newMenuReal)
    }, [leftMenuConfig]);// eslint-disable-line

    const handleToggle2 = (menu) => {
        setState2(state2 => ({...state2, [menu]: !state2[menu]}));
    };


    const {loading, permissions} = usePermissions();
    if (loading) return null;

    const handleToggle = (menu) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };


    return (
        <div style={{marginBottom: 32}}>

            {/*<DashboardMenuItem/>*/}
            <MenuItemLink
                to="/"
                primaryText={translate('ra.page.dashboard')}
                leftIcon={<DashboardIcon style={{color: 'cornflowerblue'}}/>}
                exact
            />
            <Divider/>
            {canAccess2({permissions, resource: 'createPartner', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/createPartner',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Create client'}`)}
                    leftIcon={<GroupAddIcon style={{color: 'mediumseagreen'}}/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'dRequest', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/dRequest',
                        state: {_scrollToTop: true},
                    }}
                    //primaryText={item.table}
                    primaryText={
                        <span style={{color: countRequest > 0 && "red"}}>
                        {`${translate("Customer requests")}${countRequest > 0 ? ` (${countRequest})` : ""}`}
                        </span>
                    }
                    leftIcon={<HowToRegIcon style={{color: countRequest > 0 ? 'red' : 'mediumseagreen'}}/>}
                    dense={dense}
                />}
            {canAccess2({permissions, resource: 'dCustomers', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/dCustomers',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Customers'}`)}
                    leftIcon={<GroupIcon style={{color: 'mediumseagreen'}}/>}
                    dense={dense}
                />
            }

            {canAccess2({permissions, resource: 'zCustomers', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/zCustomers',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Customers'}`)}
                    leftIcon={<GroupIcon style={{color: 'mediumseagreen'}}/>}
                    dense={dense}
                />
            }

            {canAccess2({permissions, resource: 'partner', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/partner',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Partners'}`)}
                    leftIcon={<HandshakeIcon style={{color: 'mediumseagreen'}}/>}
                    dense={dense}
                />
            }


            {canAccess2({permissions, resource: 'edit_sheduler', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/edit_sheduler',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Warehouse matrix'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'dProducts', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/dProducts',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Products'}`)}
                    leftIcon={<ProductIcon style={{color: 'blueviolet'}}/>}
                    dense={dense}
                />
            }

            {canAccess2({permissions, resource: 'dProductsGroup', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/dProductsGroup',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Categories'}`)}
                    leftIcon={<CategoryIcon style={{color: 'fuchsia'}}/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'dProductsGroupTree', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/dProductsGroupTree',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'categories_tree'}`)}
                    leftIcon={<CategoryIcon style={{color: 'fuchsia'}}/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'dBrands', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/dBrands',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Brands'}`)}
                    leftIcon={<StarIcon style={{color: 'orange'}}/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'dOrders', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/dOrders',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Orders'}`)}
                    leftIcon={<OrderIcon style={{color: 'brown'}}/>}
                    dense={dense}
                />
            }

            {canAccess2({permissions, resource: 'dPaymentProvider', action: 'list'}) &&
                <SubMenu
                    handleToggle={() => handleToggle(`Payment`)}
                    isOpen={state[`Payment`]}
                    name={translate(`Payment`).toUpperCase()}
                    icon={<PaymentsIcon style={{color: 'lightseagreen'}}/>}
                    dense={dense}
                >
                    {canAccess2({permissions, resource: 'dPayment', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPayment',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Payments`)}
                            leftIcon={<PaymentsIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dPaymentProvider', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPaymentProvider',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Payment Provider`)}
                            leftIcon={<ListAltIcon/>}
                            dense={dense}
                        />}
                </SubMenu>
            }
            {canAccess2({permissions, resource: 'dPromotion', action: 'list'}) &&
                <SubMenu
                    handleToggle={() => handleToggle(`Promotion`)}
                    isOpen={state[`Promotion`]}
                    name={translate(`Promotion`).toUpperCase()}
                    icon={<ClassifiersIcon color={'#2196f3'}/>}
                    dense={dense}
                >
                    {canAccess2({permissions, resource: 'dPromotion', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPromotion',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Promotion`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dPromotionBadge', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPromotionBadge',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Promotion Badge`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dDiscountConditionsDocument', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dDiscountConditionsDocument',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Discount Document`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dPromotionalGood', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPromotionalGood',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Promotional Good`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                </SubMenu>
            }

            {canAccess2({permissions, resource: 'dPrompts', action: 'list'}) &&
                <SubMenu
                    handleToggle={() => handleToggle(`Prompts`)}
                    isOpen={state[`Prompts`]}
                    name={translate(`Prompts`).toUpperCase()}
                    icon={<MyAiIcons color={'#2196f3'}/>}
                    dense={dense}
                >
                    {canAccess2({permissions, resource: 'dPrompts', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPrompts',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Prompts`)}
                            leftIcon={<MyAiIcons/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dPromptIndustries', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPromptIndustries',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Prompt Industries`)}
                            leftIcon={<MyAiIcons/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dPromptTypes', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPromptTypes',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Prompt Types`)}
                            leftIcon={<MyAiIcons/>}
                            dense={dense}
                        />}
                </SubMenu>
            }
            {canAccess2({permissions, resource: 'dFeatureType', action: 'list'}) &&
                <SubMenu
                    handleToggle={() => handleToggle(`Classifiers`)}
                    isOpen={state[`Classifiers`]}
                    name={translate(`Classifiers`).toUpperCase()}
                    icon={<ClassifiersIcon style={{color: 'sienna'}}/>}
                    dense={dense}
                >
                    {canAccess2({permissions, resource: 'dFeatureType', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dFeatureType',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Product characteristic`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}

                    {canAccess2({permissions, resource: 'dCurrencies', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dCurrencies',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Currencies`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dCountries', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dCountries',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Countries`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dManager', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dManager',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Manager`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dCompanyBranch', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dCompanyBranch',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Company Branch`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dMessenger', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dMessenger',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Messenger`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dPhoneMessenger', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dPhoneMessenger',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Phone Messenger`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dSuppliers', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dSuppliers',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Suppliers`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dExternalSuppliers', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dExternalSuppliers',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`External Suppliers`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dSizeChart', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dSizeChart',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Size Chart`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dColor', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dColor',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Color`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dUnitsOfMeasurement', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dUnitsOfMeasurement',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Units Of Measurement`)}
                            leftIcon={<ClassifiersIcon/>}
                            dense={dense}
                        />}

                </SubMenu>
            }
            <Divider/>
            {canAccess2({permissions, resource: 'localization', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/json-localizations',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Localization'}`)}
                    leftIcon={<TranslateIcon style={{color: 'darkcyan'}}/>}
                    dense={dense}
                />
            }
            <Divider/>
            {canAccess2({permissions, resource: 'dConfig', action: 'list'}) &&
                <SubMenu
                    handleToggle={() => handleToggle(`Config`)}
                    isOpen={state[`Config`]}
                    name={translate(`Service`).toUpperCase()}
                    icon={<SettingsIcon/>}
                    dense={dense}
                >
                    {canAccess2({permissions, resource: 'dConfig', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dConfig/1',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Config`)}
                            leftIcon={<BuildIcon/>}
                            dense={dense}
                        />}

                    {canAccess2({permissions, resource: 'dLoadPrice', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dLoadPrice',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Load Price'}`)}
                            leftIcon={<PriceChangeIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'dProcessing', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dProcessing',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Processing'}`)}
                            leftIcon={<UpdateIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'dMenuItems', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dMenuItems',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Menu Items'}`)}
                            leftIcon={<MenuIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'dBlogArticle', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dBlogArticle',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Blog Article'}`)}
                            leftIcon={<ArticleIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'dNews', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dNews',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'News'}`)}
                            leftIcon={<NewspaperIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'dSeoKeyWords', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dSeoKeyWords',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Seo Keys'}`)}
                            leftIcon={<KeyIcon/>}
                            dense={dense}
                        />
                    }

                </SubMenu>
            }
            {canAccess2({permissions, resource: 'dUser', action: 'list'}) &&
                <SubMenu
                    handleToggle={() => handleToggle(`Admin`)}
                    isOpen={state[`Admin`]}
                    name={translate(`Admin`).toUpperCase()}
                    //icon={it.icon}
                    dense={dense}
                >
                    {canAccess2({permissions, resource: 'dUser', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dUser',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Users`)}
                            leftIcon={<GroupIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dUser', action: 'create'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dUser/create',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Create user`)}
                            leftIcon={<GroupIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dUsersInterfaces', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dUsersInterfaces',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Users Interfaces`)}
                            leftIcon={<GroupIcon/>}
                            dense={dense}
                        />}
                    {canAccess2({permissions, resource: 'dInterfaces', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/dInterfaces',
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`Interfaces`)}
                            leftIcon={<MenuIcon/>}
                            dense={dense}
                        />}
                </SubMenu>
            }

            <Divider/>
            {/*/api/blog/admin-blog/
            api/blog/admin-forum/*/}
            {menuReal.map(it => {
                return (
                    <SubMenu
                        handleToggle={() => handleToggle(it.name)}
                        isOpen={state[it.name]}
                        //name={it.name}
                        name={translate(`${it.name}`).toUpperCase()}
                        icon={it.icon}
                        dense={dense}
                    >
                        {tableConfig.tablesList.filter(x => x.menu === it.name).map((item, index) => {
                            return (
                                <MenuItemLink
                                    key={index}
                                    to={{
                                        pathname: '/' + item.table,
                                        state: {_scrollToTop: true},
                                    }}
                                    //primaryText={item.table}
                                    primaryText={translate(`${item.table}`)}
                                    leftIcon={<BusinessIcon/>}
                                    dense={dense}
                                />
                            )
                        })
                        }
                    </SubMenu>
                )
            })
            }
            {tableConfig.tablesList.filter(x => x.menu === '').map((item, index) => {
                if (canAccess2({permissions, resource: item.table, action: 'list'}))
                    return (
                        <MenuItemLink
                            key={index}
                            to={{
                                pathname: '/' + item.table,
                                state: {_scrollToTop: true},
                            }}
                            //primaryText={item.table}
                            primaryText={translate(`${item.table}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    )
                return true
            })
            }

            <Divider/>
            {canAccess2({permissions, resource: 'test_price', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/test_price',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Test Price'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'gift_pool', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/gift_pool',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Bonuses'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'customer_z', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/customer_z',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Customers'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'product_z', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/product_z',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Product'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'discountProperty', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/discountProperty',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Discount Property'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            }
            {canAccess2({permissions, resource: 'discount_rules', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/discount_rules',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Discount Rules'}`)}
                    leftIcon={<BusinessIcon/>}
                    dense={dense}
                />
            }
            <Divider/>
            {canAccess2({permissions, resource: 'related_table', action: 'list'}) &&
                <SubMenu
                    handleToggle={() => handleToggle2('related_table')}
                    isOpen={state2['related_table']}
                    //name={it.name}
                    name={translate(`${'Related Table'}`).toUpperCase()}
                    //icon={it.icon}
                    dense={dense}
                >

                    {canAccess2({permissions, resource: 'discount_relationship', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/discount_relationship',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Discount Relationship'}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'product_property', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/product_property',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Product Property Value'}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'customer_property', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/customer_property',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Customer Property Value'}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'discountPropertyValue', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/discountPropertyValue',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Discount Property Value'}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'currency_z', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/currency_z',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Currency'}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'brand_z', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/brand_z',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Brands'}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    }
                    {canAccess2({permissions, resource: 'price_row_z', action: 'list'}) &&
                        <MenuItemLink
                            to={{
                                pathname: '/price_row_z',
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`${'Price Row'}`)}
                            leftIcon={<BusinessIcon/>}
                            dense={dense}
                        />
                    }

                </SubMenu>
            }

        </div>
    )
};