import * as React from 'react';
import {
    Box,
    TextField, Typography,
    useMediaQuery
} from "@material-ui/core";

import {
    DateField,
    Labeled,
    useLocale,
    useTranslate
} from "react-admin";
import {
    // choicesBusinessType,
    choicesContractorType,
    choicesHowShowQuantity,
    choicesNdsKey
} from "../../_constants/choices";
import Asynchronous2 from "../../_common/AsynchronousRequests2";
import InputMask from "react-input-mask";
import {Grid} from "@mui/material";
import {GetArrayFieldFromResource} from "./Partner";
import {useEffect, useRef} from "react";


export const EditCustomers = props => {
    const {
        handleChangeSel,
        handleChangeText,
        form,
        // setForm,
        // iniCustomers,
        index,
        handleTest,
        pricingProperty,
        requestData
    } = props

    const translate = useTranslate();
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm'); //xs  sm
    });

    const nameCustomerRef = useRef(null);
    const phoneCustomerRef = useRef(null);
    const emailCustomerRef = useRef(null);

    useEffect(() => {
        if (requestData) {
            const refs = [nameCustomerRef, emailCustomerRef, null];
            let index = 0;
            const interval = setInterval(() => {
                if (refs[index]) {
                    refs[index].current?.focus(); // Устанавливаем фокус
                    refs[index].current.blur(); // Убираем фокус
                    //index = (index + 1) % refs.length; // Переход к следующему элементу
                    index += 1
                } else {
                    // if(nameCustomerRef.current) nameCustomerRef.current.blur(); // Убираем фокус
                    // nameRef.current?.focus()
                    clearInterval(interval)
                }
            }, 1000);

            return () => clearInterval(interval); // Очищаем таймер при размонтировании
        }
    }, [requestData]);


    const handleChangePricing = (index, value, ind2) => {
        let price = JSON.parse(JSON.stringify(form.customers[index].values.customer_pricing_property))
        price[ind2].value = value;
        handleChangeSel('customers', index, 'customer_pricing_property', price)
    };

    return (
        <Box p={!isSmall && "1em"} fullWidth>
            <Box mt={'-1em'} style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Typography variant="body" color="textPrimary">
                    <b>{translate("Common data")}</b>
                </Typography>
            </Box>
            <Box mt={'1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                    <TextField
                        inputRef={nameCustomerRef}
                        fullWidth
                        label={translate("Customer name")}
                        value={form.customers[index].values.name}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'name', event)}
                        onBlur={(event) => {
                            let dt = {
                                label: `${translate('Customer')} (${translate("Customer name")}): ${translate('this value already exists')}`,
                                // label: translate('Customer with this name has been found'),
                                resource: 'customers',
                                name_for_save: '',
                                source: 'name',
                                snap: 'customers',
                                index: index,
                                name: 'name',
                                value: form.customers[index].values.name,
                                data: [],
                            }
                            handleTest(dt)
                        }}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.name || form.customers[index].error_double?.name}
                        helperText={form.customers[index].error_double?.name ? form.customers[index].error_double.name : null}
                    />
                </Box>
                <Box flex={1}>
                    <TextField
                        fullWidth
                        label={translate("Official name")}
                        value={form.customers[index].values.official_name}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'official_name', event)}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.official_name}
                    />
                </Box>
            </Box>
            <Box mt={'1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                    <TextField
                        fullWidth
                        label={translate("Code from the accounting system")}
                        value={form.customers[index].values.item_code_in_accounting_system}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'item_code_in_accounting_system', event)}
                        onBlur={(event) => {
                            let dt = {
                                label: `${translate('Customer')} (${translate("Code from the accounting system")}): ${translate('this value already exists')}`,
                                // label: translate('Customer with this code has been found'),
                                resource: 'customers',
                                name_for_save: '',
                                source: 'item_code_in_accounting_system',
                                snap: 'customers',
                                index: index,
                                name: 'item_code_in_accounting_system',
                                value: form.customers[index].values.item_code_in_accounting_system,
                                data: [],
                            }
                            handleTest(dt)
                        }}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.item_code_in_accounting_system || form.customers[index].error_double?.item_code_in_accounting_system}
                        helperText={form.customers[index].error_double?.item_code_in_accounting_system ? form.customers[index].error_double.item_code_in_accounting_system : null}
                    />
                </Box>
                <Box flex={1}>
                    <TextField
                        fullWidth
                        label={translate("Client code")}
                        value={form.customers[index].values.client_code}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'client_code', event)}
                        onBlur={(event) => {
                            let dt = {
                                label: `${translate('Customer')} (${translate("Client code")}): ${translate('this value already exists')}`,
                                // label: translate('Customer with this client code has been found'),
                                resource: 'customers',
                                name_for_save: '',
                                source: 'client_code',
                                snap: 'customers',
                                index: index,
                                name: 'client_code',
                                value: form.customers[index].values.client_code,
                                data: [],
                            }
                            handleTest(dt)
                        }}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.client_code || form.customers[index].error_double?.client_code}
                        helperText={form.customers[index].error_double?.client_code ? form.customers[index].error_double.client_code : null}
                    />
                </Box>
            </Box>

            <Box mt={'2em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                    <Asynchronous2
                        label={translate('Main warehouse')}
                        value={form.customers[index].values.base_warehouse_main_supplier}
                        setValue={(value) => handleChangeSel('customers', index, 'base_warehouse_main_supplier', value)}
                        // domain={config.apiPrices}
                        url={'api/react-admin/main/SuppliersWarehouses'}
                        // outputField='id'
                        searchField={'search'}
                        getOptionLabel={(option) => `${option['name_' + locale]}`}
                        error={form.customers[index].error?.base_warehouse_main_supplier}
                    />
                </Box>
                <Box flex={1}>
                    <InputMask
                        inputRef={phoneCustomerRef}
                        mask="+380 (99) 999-99-99"
                        fullWidth
                        label={translate("Phone")}
                        value={form.customers[index].values.phone}
                        name={'phone'}
                        onChange={(event) => handleChangeText('customers', index, 'phone', event)}
                        size={"small"}
                        variant="outlined"
                        //error={form.partner.error?.phone}
                        //InputProps={{classes: {input: classes.input1}}}
                    >
                        {(inputProps) =>
                            <TextField
                                {...inputProps}
                                variant="outlined"
                                type="tel"
                                // disableUnderline
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                error={form.customers[index].error?.phone}
                                //helperText={hasError('phone') ? formState.errors.phone[0] : null}
                            />}
                    </InputMask>

                    {/*<TextField
                        fullWidth
                        label={translate("Phone")}
                        value={form.customers[index].values.phone}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'phone', event)}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.phone}
                    />*/}
                </Box>
            </Box>
            <Box mt={'1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                    <TextField
                        fullWidth
                        label={translate("Physical address")}
                        value={form.customers[index].values.billing_address}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'billing_address', event)}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.billing_address}
                    />
                </Box>
                <Box flex={1}>
                    <TextField
                        fullWidth
                        label={translate("Legal address")}
                        value={form.customers[index].values.address}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'address', event)}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.address}
                    />
                </Box>
            </Box>
            <Box mt={'1em'} display={isSmall ? 'grid' : 'flex'} alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"} display={isSmall ? 'grid' : 'flex'}>
                    <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                        <InputMask
                            mask="9999999999"
                            fullWidth
                            label={translate("Tax number")}
                            value={form.customers[index].values.tax_number}
                            name={'tax_number'}
                            onChange={(event) => handleChangeText('customers', index, 'tax_number', event)}
                            size={"small"}
                            variant="outlined"
                            //error={form.partner.error?.tax_number}
                            //InputProps={{classes: {input: classes.input1}}}
                        >
                            {(inputProps) =>
                                <TextField
                                    {...inputProps}
                                    variant="outlined"
                                    type="tel"
                                    // disableUnderline
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    error={form.customers[index].error?.tax_number}
                                    //helperText={hasError('tax_number') ? formState.errors.tax_number[0] : null}
                                />}
                        </InputMask>
                        {/*<TextField
                            // type={"number"}
                            fullWidth
                            // maxLength={10}
                            label={translate("Tax number")}
                            value={form.customers[index].values.tax_number}
                            //name={'name'}
                            onChange={(event) => handleChangeText('customers', index, 'tax_number', event, 10)}
                            size={"small"}
                            variant="outlined"
                            error={form.customers[index].error?.tax_number}
                        />*/}
                    </Box>
                    <Box flex={1}>
                        <InputMask
                            mask="99999999"
                            fullWidth
                            label={translate("EDRPOU")}
                            value={form.customers[index].values.edrpou}
                            name={'edrpou'}
                            onChange={(event) => handleChangeText('customers', index, 'edrpou', event)}
                            onBlur={(event) => {
                                let dt = {
                                    label: `${translate('Customer')} (${translate("EDRPOU")}): ${translate('this value already exists')}`,
                                    // label: translate('Customer with this EDRPOU has been found'),
                                    resource: 'customers',
                                    name_for_save: '',
                                    source: 'edrpou',
                                    snap: 'customers',
                                    index: index,
                                    name: 'edrpou',
                                    value: form.customers[index].values.edrpou,
                                    data: [],
                                }
                                handleTest(dt)
                            }}
                            size={"small"}
                            variant="outlined"
                            //error={form.partner.error?.edrpou}
                            //InputProps={{classes: {input: classes.input1}}}
                        >
                            {(inputProps) =>
                                <TextField
                                    {...inputProps}
                                    variant="outlined"
                                    type="tel"
                                    // disableUnderline
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    error={form.customers[index].error?.edrpou}
                                    //helperText={hasError('edrpou') ? formState.errors.edrpou[0] : null}
                                />}
                        </InputMask>
                        {/*<TextField
                            // type={"number"}
                            fullWidth
                            label={translate("EDRPOU")}
                            value={form.customers[index].values.edrpou}
                            //name={'name'}
                            onChange={(event) => handleChangeText('customers', index, 'edrpou', event, 20)}
                            onBlur={(event) => {
                                let dt = {
                                    label: translate('Customer with this EDRPOU has been found'),
                                    resource: 'customers',
                                    name_for_save: '',
                                    source: 'edrpou',
                                    snap: 'customers',
                                    index: index,
                                    name: 'edrpou',
                                    value: form.customers[index].values.edrpou,
                                    data: [],
                                }
                                handleTest(dt)
                            }}
                            size={"small"}
                            variant="outlined"
                            //type={"number"}
                            error={form.customers[index].error?.edrpou || form.customers[index].error_double?.edrpou}
                            helperText={form.customers[index].error_double?.edrpou ? form.customers[index].error_double.edrpou : null}
                        />*/}
                    </Box>
                </Box>
                <Box flex={1} display={isSmall ? 'grid' : 'flex'}>
                    <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                        <TextField
                            fullWidth
                            label={translate("VAT identification number")}
                            value={form.customers[index].values.vat_number}
                            //name={'name'}
                            onChange={(event) => handleChangeText('customers', index, 'vat_number', event)}
                            inputProps={{maxLength: 12}}
                            onBlur={(event) => {
                                let dt = {
                                    label: `${translate('Customer')} (${translate("VAT identification number")}): ${translate('this value already exists')}`,
                                    // label: translate('Customer with this vat number has been found'),
                                    resource: 'customers',
                                    name_for_save: '',
                                    source: 'vat_number',
                                    snap: 'customers',
                                    index: index,
                                    name: 'vat_number',
                                    value: form.customers[index].values.vat_number,
                                    data: [],
                                }
                                handleTest(dt)
                            }}
                            size={"small"}
                            variant="outlined"
                            //type={"number"}
                            error={form.customers[index].error?.vat_number || form.customers[index].error_double?.vat_number}
                            helperText={form.customers[index].error_double?.vat_number ? form.customers[index].error_double.vat_number : null}
                        />
                    </Box>
                    <Box flex={1}>
                        <Asynchronous2
                            label={translate('VAT payer')}
                            value={form.customers[index].values.nds_key}
                            setValue={(value) => handleChangeSel('customers', index, 'nds_key', value)}
                            // domain={config.apiPrices}
                            //url={''}
                            choices={choicesNdsKey[locale]}
                            // outputField='id'
                            //searchField={'search'}
                            getOptionLabel={(option) => `${option.name}`}
                            error={form.customers[index].error?.nds_key}
                        />
                    </Box>
                </Box>
            </Box>

            <Box mt={'2em'} display={isSmall ? 'grid' : 'flex'} alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"} display={isSmall ? 'grid' : 'flex'}>
                    <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                        <TextField
                            //type={"number"}
                            fullWidth
                            label={translate("Credit limit amount")}
                            value={form.customers[index].values.credit_limit_sum}
                            //name={'name'}
                            onChange={(event) => handleChangeText('customers', index, 'credit_limit_sum', event, 20)}
                            size={"small"}
                            variant="outlined"
                            error={form.customers[index].error?.credit_limit_sum}
                        />
                    </Box>
                    <Box flex={1}>
                        <Asynchronous2
                            label={translate('Credit limit currency')}
                            value={form.customers[index].values.credit_limit_currency}
                            setValue={(value) => handleChangeSel('customers', index, 'credit_limit_currency', value)}
                            // domain={config.apiPrices}
                            url={'api/react-admin/main/Currencies'}
                            // outputField='id'
                            searchField={'search'}
                            getOptionLabel={(option) => `${option['simbol']}`}
                            // getOptionLabel={(option) => `${option['simbol' + locale]}`}
                            error={form.customers[index].error?.credit_limit_currency}
                        />
                    </Box>
                </Box>
                <Box flex={1} display={isSmall ? 'grid' : 'flex'}>
                    <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                        <TextField
                            fullWidth
                            //type={"number"}
                            label={translate("Credit limit term (days)")}
                            value={form.customers[index].values.credit_limit_days}
                            //name={'name'}
                            onChange={(event) => handleChangeText('customers', index, 'credit_limit_days', event, 20)}
                            size={"small"}
                            variant="outlined"
                            error={form.customers[index].error?.credit_limit_days}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextField
                            fullWidth
                            //type={"number"}
                            label={translate("Retail margin")}
                            value={form.customers[index].values.retail_margin}
                            //name={'name'}
                            onChange={(event) => handleChangeText('customers', index, 'retail_margin', event, 20)}
                            size={"small"}
                            variant="outlined"
                            error={form.customers[index].error?.retail_margin}
                        />
                    </Box>
                </Box>
            </Box>
            <Box mt={'1em'} display={isSmall ? 'grid' : 'flex'} alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                    <Asynchronous2
                        label={translate('Contractor type')}
                        value={form.customers[index].values.contractor_type}
                        setValue={(value) => handleChangeSel('customers', index, 'contractor_type', value)}
                        // domain={config.apiPrices}
                        //url={''}
                        choices={choicesContractorType[locale]}
                        // outputField='id'
                        //searchField={'search'}
                        getOptionLabel={(option) => `${option.name}`}
                        error={form.customers[index].error?.contractor_type}
                    />
                </Box>
                <Box flex={1} display={'grid'}>
                    <Asynchronous2
                        label={translate('Business type')}
                        value={form.customers[index].values.business_type}
                        setValue={(value) => handleChangeSel('customers', index, 'business_type', value)}
                        // domain={config.apiPrices}
                        url={'api/react-admin/main/BusinessType'}
                        // outputField='id'
                        searchField={'search'}
                        getOptionLabel={(option) => `${option['name_' + locale]}`}
                        error={form.customers[index].error?.business_type}
                    />
                    {requestData && requestData.business_types &&
                        <Box mt={'-8px'} display={'flex'}>
                            <Box display={'flex'} style={{flexWrap: 'wrap', padding: 8}}>
                                <span style={{color: 'green', fontSize: 14}}><b>{translate('Пожелание клиента')}:</b></span>
                            </Box>
                            <GetArrayFieldFromResource
                                resource={'dBusinessType'}
                                field={`name_${locale}`}
                                id={requestData.business_types}
                                type={'text'}
                                fontSize={14}
                            />
                        </Box>
                    }
                </Box>
            </Box>
            {/*<Box mt={'1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <Asynchronous2
                        label={translate('How to show quantity')}
                        value={form.customers[index].values.how_show_quantity}
                        setValue={(value) => handleChangeSel('customers', index, 'how_show_quantity', value)}
                        // domain={config.apiPrices}
                        //url={''}
                        choices={choicesHowShowQuantity[locale]}
                        // outputField='id'
                        //searchField={'search'}
                        getOptionLabel={(option) => `${option.name}`}
                        error={form.customers[index].error?.how_show_quantity}
                    />
                </Box>
            </Box>*/}

            <Box mt={'2em'} style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                    <InputMask
                        mask="+380 (99) 999-99-99"
                        fullWidth
                        label={`${translate("Phone")} 2`}
                        value={form.customers[index].values.phone2}
                        name={'phone2'}
                        onChange={(event) => handleChangeText('customers', index, 'phone2', event)}
                        size={"small"}
                        variant="outlined"
                        //error={form.partner.error?.phone2}
                        //InputProps={{classes: {input: classes.input1}}}
                    >
                        {(inputProps) =>
                            <TextField
                                {...inputProps}
                                variant="outlined"
                                type="tel"
                                // disableUnderline
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                error={form.customers[index].error?.phone2}
                                //helperText={hasError('phone2') ? formState.errors.phone2[0] : null}
                            />}
                    </InputMask>
                    {/*<TextField
                        fullWidth
                        label={`${translate("Phone")} 2`}
                        value={form.customers[index].values.phone2}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'phone2', event)}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.phone2}
                    />*/}
                </Box>
                <Box flex={1}>
                    <InputMask
                        mask="+380 (99) 999-99-99"
                        fullWidth
                        label={`${translate("Phone")} 3`}
                        value={form.customers[index].values.phone3}
                        name={'phone3'}
                        onChange={(event) => handleChangeText('customers', index, 'phone3', event)}
                        size={"small"}
                        variant="outlined"
                        //error={form.partner.error?.phone3}
                        //InputProps={{classes: {input: classes.input1}}}
                    >
                        {(inputProps) =>
                            <TextField
                                {...inputProps}
                                variant="outlined"
                                type="tel"
                                // disableUnderline
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                error={form.customers[index].error?.phone3}
                                //helperText={hasError('phone3') ? formState.errors.phone3[0] : null}
                            />}
                    </InputMask>
                    {/*<TextField
                        fullWidth
                        label={`${translate("Phone")} 3`}
                        value={form.customers[index].values.phone3}
                        //name={'name'}
                        onChange={(event) => handleChangeText('customers', index, 'phone3', event)}
                        size={"small"}
                        variant="outlined"
                        //type={"number"}
                        error={form.customers[index].error?.phone3}
                    />*/}
                </Box>
            </Box>

            <Box mt={'1em'} style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Typography variant="body" color="textPrimary">
                    <b>{translate("Settings for pricing")}</b>
                </Typography>
            </Box>

            <Box m={'8px -8px 0 -8px'} alignItems={"top"}>
                <Grid container>
                    {pricingProperty && pricingProperty.length > 0 &&
                        form.customers[index].values.customer_pricing_property.map((it2, ind2) => {
                            return (
                                <Grid item xs={12} md={6} p={1} key={it2.id}>
                                    <Asynchronous2
                                        label={it2.name}
                                        value={it2.value}
                                        setValue={(value) => handleChangePricing(index, value, ind2)}
                                        // domain={config.apiPrices}
                                        //url={'api/react-admin/main/Delivery'}
                                        choices={pricingProperty.find(x => x.id === it2.id).discount_property_valid_values}
                                        // outputField='id'
                                        //searchField={'search'}
                                        getOptionLabel={(option) => `${option.name}`}
                                        error={!it2.value}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>

            <Box mt={'1em'} style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Typography variant="body" color="textPrimary">
                    <b>{translate("Customer users")}</b>
                </Typography>
            </Box>

            <Box m={'8px -8px 0 -8px'} alignItems={"top"}>
                <Grid container>
                    <Grid item xs={12} md={6} p={1}>
                        <Asynchronous2
                            label={translate('Select a user from the list')}
                            value={form.customers[index].values.user_id}
                            setValue={(value) => handleChangeSel('customers', index, 'user_id', value)}
                            // domain={config.apiPrices}
                            // url={'api/react-admin/auth/User'}
                            url={'api/unconnected-users/'}
                            filter={`&partner_id=${form.partner.values.id ? form.partner.values.id.id : '0'}`}
                            // outputField='id'
                            searchField={'search'}
                            getOptionLabel={(option) => `${option.email}`}
                            error={form.customers[index].error?.user_id}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} p={1}>
                        {form.customers[index].values.user_id === null &&
                            <TextField
                                fullWidth
                                inputRef={emailCustomerRef}
                                label={`${translate("Enter new user")}`}
                                value={form.customers[index].values.user_email}
                                //name={'name'}
                                onChange={(event) => handleChangeText('customers', index, 'user_email', event)}
                                onBlur={(event) => {
                                    let dt = {
                                        label: `${translate('User')} (${translate("Email")}): ${translate('this value already exists')}`,
                                        // label: translate('User with this email has been found'),
                                        resource: 'user',
                                        name_for_save: 'user_id',
                                        source: 'email',
                                        snap: 'customers',
                                        index: index,
                                        name: 'user_email',
                                        value: form.customers[index].values.user_email,
                                        data: [],
                                    }
                                    handleTest(dt)
                                }}
                                size={"small"}
                                variant="outlined"
                                type={"email"}
                                error={form.customers[index].error?.user_email || form.customers[index].error_double?.user_email}
                                helperText={form.customers[index].error_double?.user_email ? form.customers[index].error_double.user_email : null}
                            />
                        }
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )

}
