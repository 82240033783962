import * as React from 'react';
import {Box, Checkbox, TextField, Typography, useMediaQuery} from "@material-ui/core";
import {useGetMany, useGetOne, useNotify, useTranslate} from "react-admin";
import {useEffect, useRef} from "react";
import validate from 'validate.js';
import Asynchronous2 from "../../_common/AsynchronousRequests2";
import get from "lodash/get";
import {Chip} from "@mui/material";
import InputMask from 'react-input-mask';
import {PartnerData} from "./PartnerData";
import Button from "@material-ui/core/Button";
// import dataProvider from "../../utils/dataProvider";

const schema = {
    /*manager: {
        presence: {allowEmpty: false, message: 'is required'},
    },*/
    name: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 2,
            maximum: 256,
        },
    },
    email: {
        presence: {allowEmpty: false},
        email: true,
        length: {
            minimum: 6,
            maximum: 128,
        },
    },
    accounting_email: {
        presence: {allowEmpty: true},
        email: true,
        length: {
            minimum: 6,
            maximum: 128,
        },
    },
    phone: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {
            minimum: 6,
            maximum: 30,
        },
    },
};

export function getFieldFromChoice(choiceArray, id, field = null) {
    let title = field ? '' : {}
    if (id) {
        let res = choiceArray.find(x => x.id === id)
        if (res) {
            title = field ? res[field] : res
        } else {
            title = field ? '' : {}
        }
    }
    return title
}

export function getFieldFromResource(resource, id, field = null) {
    let title = field ? '' : {}
    if (id) {
        const {data, loaded} = useGetOne(resource, id)
        title = '...loading'
        if (loaded && data) {
            title = field ? get(data, field, null) : data
        } else {
            title = field ? '' : {}
        }
    }
    return title
}

export const GetFieldFromChoice = props => {
    const {choiceArray, id, field} = props
    let title = ''
    if (id) {
        let res = choiceArray.find(x => x.id === id)
        if (res) {
            title = res[field]
        }
    }
    return title
}

export const GetFieldFromResource = props => {
    const {resource, field, id} = props
    let title = ''
    if (id) {
        const {data, loaded} = useGetOne(resource, id)
        title = '...loading'
        if (loaded && data) title = get(data, field, null);
    }
    return title
}


export const GetArrayFieldFromResource = props => {
    const {grid = false, resource, field, id, type = 'chip', fontSize = null} = props
    let dt = []
    if (id) {
        const {data, loaded} = useGetMany(resource, id)
        // console.log('GetArrayFieldFromResource==1====>', data)
        if (loaded && data && data[0] && data[0][field]) {
            // console.log('GetArrayFieldFromResource======>', data)
            dt = data
            //title = get(data, field, null);
        }
    }
    if (type === 'text')
        return (
            <Box display={grid ? 'grid' : 'flex'}
                 style={{flexWrap: 'wrap', padding: 8, fontSize: fontSize && fontSize}}>
                {dt.map((it, ind) => {
                    let name = it[field]
                    if (resource === 'delivery')
                        name = `${it[field]}[${it?.time_dispatch}][${it.additional?.week_days}]`
                    return <span style={{marginBottom: grid && 8}}
                                 key={ind}>{name}{ind < dt.length - 1 ? ', ' : ''}</span>
                })
                }
            </Box>
        )
    else
        return (
            <Box display={'flex'} style={{flexWrap: 'wrap'}}>
                {dt.map((it, ind) => {
                    let name = it[field]
                    if (resource === 'delivery')
                        name = `${it[field]}[${it?.time_dispatch}][${it.additional?.week_days}]`
                    return <Chip sx={{mb: 1, ml: 1}} label={name} key={ind}/>
                })
                }
            </Box>
        )
}

export const Partner = props => {

    const {
        handleChangeSel,
        handleChangeText,
        form,
        setForm,
        handleTest,
        handleSetForm,
        setPartnerAddress,
        setPartnerCustomers,
        partnerAddress,
        setActiveStep,
        requestData,
    } = props

    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm'); //xs  sm
    });
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    // const notify = useNotify();

    // const hasError = field => form.partner.error[field] //hasError(nameField)

    useEffect(() => {
        if (requestData) {
            const refs = [emailRef, phoneRef, nameRef, null];
            let index = 0;
            const interval = setInterval(() => {
                if (refs[index]) {
                    refs[index].current?.focus(); // Устанавливаем фокус
                    refs[index].current.blur();
                    //index = (index + 1) % refs.length; // Переход к следующему элементу
                    index += 1
                } else {
                    // if(emailRef.current) emailRef.current.blur(); // Убираем фокус
                    // nameRef.current?.focus()
                    clearInterval(interval)
                }
            }, 1000);

            return () => clearInterval(interval); // Очищаем таймер при размонтировании
        }
    }, [requestData]);

    useEffect(() => {

        let error = validate(form.partner.values, schema);

        if (form.partner.values.accounting_email === '' && error.accounting_email) delete error.accounting_email

        error = error ? error : {}
        if (form.partner.values.phone.replace(/\D/g, '').length === 12) delete error.phone
        else error.phone = ['is required']

        let error_double = form.partner.error_double ? form.partner.error_double : {}
        let valid = (Object.keys(error_double).length === 0 && Object.keys(error).length === 0) || form.partner.values.id ? true : false
        console.log('errorsSt======>', error)
        setForm(form => ({
            ...form,
            partner: {
                ...form.partner,
                error: error,
                is_valid: valid,
            }
        }));
    }, [form.partner.values, form.partner.error_double]); // eslint-disable-line


    return (
        <Box
            display={"grid"}
            sx={{width: '100%'}}
            //     style={{margin: 16, border: '1px solid #ccc', borderRadius: 4, backgroundColor: "white", padding: 16}}
        >
            <h3>{translate(`Create client`)}</h3>
            <Box
                display={"grid"}
                sx={{backgroundColor: '#fff', padding: 16}}
                //     style={{margin: 16, border: '1px solid #ccc', borderRadius: 4, backgroundColor: "white", padding: 16}}
            >
                <h3>{translate(`Partner from list`)}</h3>
                <Box display={isSmall ? "grid" : "flex"} alignItems={"center"} mt={1}>
                    <Box flex={2}>
                        <Asynchronous2
                            //value={form.partner.values.id}
                            value={form.partner.values.id}
                            setValue={(value) => handleChangeSel('partner', null, 'id', value)}
                            // domain={config.apiPrices}
                            url={'api/react-admin/main/Partner'}
                            // outputField='id'
                            searchField={'search'}
                            getOptionLabel={(option) => `${option.name}${' / '}${option.email}${' / '}${option.phone}`}
                            /*getOptionLabel={(option) => (
                                <span style={{
                                    display: "inline-block",
                                    maxWidth: isSmall && "300px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}>
                                    {`${option.name} / ${option.email} / ${option.phone}`}
                                </span>
                            )}*/
                            label={translate('Partners')}
                        />
                    </Box>
                    {form.partner.values.id &&
                        <Box ml={!isSmall && 2} mt={isSmall && 2}>
                            <Button
                                style={{width: 190}}
                                //disabled={!isValidStep(activeStep)}
                                variant="contained"
                                color="primary"
                                onClick={(event) => {
                                    handleSetForm('partner', null, 'id', null)
                                }}
                                //className={classes.button}
                            >
                                {translate('Create client')}
                            </Button>
                        </Box>
                    }
                </Box>
                {form.partner.values.id &&
                    <PartnerData
                        {...props}
                        partner={form.partner.values.id}
                        partnerAddress={partnerAddress}
                    />
                }
                {form.partner.values.id === null &&
                    <>
                        <h3>{translate(`New partner`)}</h3>
                        <Box display={isSmall ? "grid" : "flex"} alignItems={"center"} mt={1}>
                            <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                                <TextField
                                    inputRef={nameRef}
                                    autoComplete={'new-password'}
                                    fullWidth
                                    label={translate("Name")}
                                    value={form.partner.values.name}
                                    name={'name'}
                                    onChange={(event) => handleChangeText('partner', null, 'name', event)}
                                    onBlur={(event) => {
                                        let dt = {
                                            label: `${translate('Partner')} (${translate("Name")}): ${translate('this value already exists')}`,
                                            resource: 'partner',
                                            name_for_save: 'id',
                                            source: 'name',
                                            snap: 'partner',
                                            index: null,
                                            name: 'name',
                                            value: form.partner.values.name,
                                            data: [],
                                        }
                                        handleTest(dt)
                                    }}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.partner.error?.name || form.partner.error_double?.name}
                                    helperText={form.partner.error_double?.name ? form.partner.error_double.name : null}
                                />
                            </Box>
                            <Box flex={1}>
                                <Asynchronous2
                                    label={translate('Manager')}
                                    value={form.partner.values.manager}
                                    setValue={(value) => handleChangeSel('partner', null, 'manager', value)}
                                    // domain={config.apiPrices}
                                    url={'api/react-admin/main/Manager'}
                                    // outputField='id'
                                    searchField={'search'}
                                    getOptionLabel={(option) => `${option.name}`}
                                    error={form.partner.error?.manager}
                                />
                            </Box>
                        </Box>

                        <Box display={isSmall ? "grid" : "flex"} alignItems={"center"} mt={2}>
                            <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                                <TextField
                                    inputRef={emailRef}
                                    autoComplete={'new-password'}
                                    fullWidth
                                    label={translate("Email")}
                                    value={form.partner.values.email}
                                    name={'email'}
                                    onChange={(event) => handleChangeText('partner', null, 'email', event)}
                                    onBlur={(event) => {
                                        let dt = {
                                            label: `${translate('Partner')} (${translate("Email")}): ${translate('this value already exists')}`,
                                            resource: 'partner',
                                            name_for_save: 'id',
                                            source: 'email',
                                            snap: 'partner',
                                            index: null,
                                            name: 'email',
                                            value: form.partner.values.email,
                                            data: [],
                                        }
                                        handleTest(dt)
                                    }}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.partner.error?.email || form.partner.error_double?.email}
                                    helperText={form.partner.error_double?.email ? form.partner.error_double.email : null}
                                />
                            </Box>
                            <Box flex={1}>
                                <InputMask
                                    mask="+380 (99) 999-99-99"
                                    fullWidth
                                    label={translate("Phone")}
                                    value={form.partner.values.phone}
                                    name={'phone'}
                                    onChange={(event) => handleChangeText('partner', null, 'phone', event)}
                                    onBlur={(event) => {
                                        let dt = {
                                            type: 'phone',
                                            label: `${translate('Partner')} (${translate("Phone")}): ${translate('this value already exists')}`,
                                            resource: 'partner',
                                            name_for_save: 'id',
                                            source: 'phone',
                                            snap: 'partner',
                                            index: null,
                                            name: 'phone',
                                            value: form.partner.values.phone,
                                            data: [],
                                        }
                                        handleTest(dt)
                                    }}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    //error={form.partner.error?.phone}
                                    // placeholder={translate('common:enter_phone')}
                                    //InputProps={{classes: {input: classes.input1}}}
                                >
                                    {(inputProps) =>
                                        <TextField
                                            {...inputProps}
                                            inputRef={phoneRef}
                                            autoComplete={'new-password'}
                                            variant="outlined"
                                            type="tel"
                                            // disableUnderline
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            error={form.partner.error?.phone || form.partner.error_double?.phone}
                                            helperText={form.partner.error_double?.phone ? form.partner.error_double.phone : null}
                                        />}
                                </InputMask>
                            </Box>
                        </Box>

                        <Box display={isSmall ? "grid" : "flex"} alignItems={"center"} mt={2}>
                            <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                                <Asynchronous2
                                    label={translate('Sales representative SNG')}
                                    value={form.partner.values.sales_representative_1}
                                    setValue={(value) => handleChangeSel('partner', null, 'sales_representative_1', value)}
                                    // domain={config.apiPrices}
                                    url={'api/react-admin/main/Manager'}
                                    // outputField='id'
                                    searchField={'search'}
                                    getOptionLabel={(option) => `${option.name}`}
                                    error={form.partner.error?.sales_representative_1}
                                />
                            </Box>
                            <Box flex={1}>
                                <Asynchronous2
                                    label={translate('Sales representative Ino')}
                                    value={form.partner.values.sales_representative_2}
                                    setValue={(value) => handleChangeSel('partner', null, 'sales_representative_2', value)}
                                    // domain={config.apiPrices}
                                    url={'api/react-admin/main/Manager'}
                                    // outputField='id'
                                    searchField={'search'}
                                    getOptionLabel={(option) => `${option.name}`}
                                    error={form.partner.error?.sales_representative_2}
                                />
                            </Box>
                        </Box>

                        <Box display={isSmall ? "grid" : "flex"} alignItems={"center"} mt={2}>
                            <Box flex={2} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                                <TextField
                                    fullWidth
                                    label={translate("Accounting email")}
                                    value={form.partner.values.accounting_email}
                                    name={'email'}
                                    onChange={(event) => handleChangeText('partner', null, 'accounting_email', event)}
                                    size={"small"}
                                    variant="outlined"
                                    type={"email"}
                                    error={form.partner.error?.accounting_email}
                                />
                            </Box>
                            <Box flex={1} mr={!isSmall && "1em"} mb={isSmall && "1em"}>
                                <InputMask
                                    mask="+380 (99) 999-99-99"
                                    fullWidth
                                    label={translate("Accounting phone")}
                                    value={form.partner.values.accounting_phone}
                                    name={'accounting_phone'}
                                    onChange={(event) => handleChangeText('partner', null, 'accounting_phone', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.partner.error?.accounting_phone}
                                    //InputProps={{classes: {input: classes.input1}}}
                                >
                                    {(inputProps) =>
                                        <TextField
                                            {...inputProps}
                                            variant="outlined"
                                            type="tel"
                                            // disableUnderline
                                            onChange={props.onChange}
                                            error={form.partner.error?.accounting_phone}
                                            //helperText={hasError('phone') ? formState.errors.phone[0] : null}
                                        />}
                                </InputMask>

                                {/*<TextField
                                    fullWidth
                                    label={translate("Accounting phone")}
                                    value={form.partner.values.accounting_phone}
                                    name={'accounting_phone'}
                                    onChange={(event) => handleChangeText('partner', null, 'accounting_phone', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.partner.error?.accounting_phone}
                                />*/}
                            </Box>
                            <Box flex={1}>
                                <InputMask
                                    mask="+380 (99) 999-99-99"
                                    fullWidth
                                    label={translate("Warehouse phone")}
                                    value={form.partner.values.warehouse_phone}
                                    name={'warehouse_phone'}
                                    onChange={(event) => handleChangeText('partner', null, 'warehouse_phone', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.partner.error?.warehouse_phone}
                                    //InputProps={{classes: {input: classes.input1}}}
                                >
                                    {(inputProps) =>
                                        <TextField
                                            {...inputProps}
                                            variant="outlined"
                                            type="tel"
                                            // disableUnderline
                                            onChange={props.onChange}
                                            error={form.partner.error?.warehouse_phone}
                                            //helperText={hasError('phone') ? formState.errors.phone[0] : null}
                                        />}
                                </InputMask>

                                {/*<TextField
                                    fullWidth
                                    label={translate("Warehouse phone")}
                                    value={form.partner.values.warehouse_phone}
                                    name={'phone'}
                                    onChange={(event) => handleChangeText('partner', null, 'warehouse_phone', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.partner.error?.warehouse_phone}
                                />*/}
                            </Box>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    )
};