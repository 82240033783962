import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    NumberField, useTranslate,
} from 'react-admin';
import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp, NumberInputFloatComp} from "../../_common/CompReact";

// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import {FloatNumber} from "../../_common/CompFormat/utils";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {useMemo} from "react";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {gift_pool_id} = props;
    const translate = useTranslate();

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    /*
"id": 0,
  "gift_pool_id": 0,
  "product_id": "string",
  "price": 0,
  "currency_id": 0,    */
    return (
        <>
            <RowForm
                {...props}
                initialValues={{gift_pool_id: gift_pool_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceInput source="product_id" reference="product_z"
                                label={translate('Product')}
                                validate={validateReq}>
                    <AutocompleteInput
                        resettable
                        // optionText="name"
                        optionText={useMemo(() => {
                            // return ((record) => `${record?.article} - ${record?.name}`)
                            return ((record) => `${record?.article} - ${record?.name && record?.article ? record.name.replace(`(${record.article})`, '') : record?.name} (${record?.article_clean})`)
                        }, [])}
                        fullWidth
                    />
                </ReferenceInput>
                <NumberInputFloatComp source="price" label={translate('Price')}
                                      validate={validateReq}
                                      fullWidth
                />
                <ReferenceInput source="currency_id" reference="currency_z"
                                defaultValue={980}
                                label={translate('Price Currency')}
                                validate={validateReq}
                                fullWidth
                >
                    <AutocompleteInput resettable optionText="name" fullWidth/>
                </ReferenceInput>
            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        product: <ReferenceField source="product_id" reference="product_z" label={translate('Product')}
                                  link={true}
                                  sortBy={'product_z:name'}>
            <TextField source="name"/>
        </ReferenceField>,
        price: <FloatNumber source={`price`} label={translate('Price')} textAlign={"right"}/>,
        currency:
                <ReferenceField source="currency_id" reference="currency_z"
                                label={translate('Price Currency')}
                                link={false}
                                sortBy={'currency_z:name'}>
                    <TextField source="name"/>
                </ReferenceField>,
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }

    return columns;
};

const RelatedGift = props => {
    // const {customer_id, record, isSave = true} = props;
    const {gift_pool_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    // console.log('=======>', invoiceBooking);

    const columns = useSelectedColumns({
        preferences: `${'gift'}list.columns`,
        columns: ColumnsForList('gift', classes),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Bonus"
                reference="gift"
                target="gift_pool_id"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false}
                                              gift_pool_id={gift_pool_id}/>}
                    editForm={<ListEditForm submitOnEnter={false}
                                            gift_pool_id={gift_pool_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default RelatedGift;