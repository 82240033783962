import React, { useState, useEffect } from 'react';

export const windowHeight = () => {
    const [width, setWidth] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerHeight);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
};
const windowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
};

export default windowWidth;