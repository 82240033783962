import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
    useMediaQuery
} from "@material-ui/core";

import {
    useLocale,
    useNotify,
    useTranslate
} from "react-admin";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useEffect, useState} from "react";
import validate from "validate.js";
import Button from "@material-ui/core/Button";
import {GetArrayFieldFromResource} from "./Partner";
import {EditAddress} from "./EditAddress";
import {getRequestOverpass} from "../../_constants/requestOverpass";
import {nominatimOpenStreetMap} from "../../_common/CompForApi/utilsAPI";
import {PartnerAddress} from "./PartnerData";
import {Alert} from "@mui/material";

const schemaAuto = {
    customers: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    area: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    /*region: {
        presence: {allowEmpty: false, message: 'is required'},
    },*/
    point: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    street: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    house: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 1,
            maximum: 256,
        },
    },
    lat: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
    },
    lon: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    name: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 2,
            maximum: 256,
        },
    },

};
const schemaHand = {
    customers: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    area_hand: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    region_hand: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    point_hand: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    street_hand: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    house: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 1,
            maximum: 256,
        },
    },
    lat: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
    },
    lon: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    name: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 2,
            maximum: 256,
        },
    },

};

let pause = false
export const Address = props => {

    const {
        setAlarmCustomerAddress,
        alarmCustomerAddress,
        partnerCustomers,
        form,
        setForm,
        iniAddress,
        partnerAddress,
        // handleChangeSel,
        // handleChangeText,
        // handleChangeCheck,
        // setPartnerAddress,
        // areas,
    } = props


    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm'); //xs  sm
    });
    const notify = useNotify();

    const [mixCustomers, setMixCustomers] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [expandAddress, setExpandAddress] = useState(true);

    function searchAddressForCustomer(id) {
        let res = 0
        if (partnerAddress) {
            partnerAddress.forEach((it) => {
                if (it.customer_cross && it.customer_cross.find(x => x.id === id)) res += 1
            })
        }
        if (form.address) {
            Object.values(form.address).forEach((it) => {
                if (it.values.customers && it.values.customers.find(x => x.id === id)) res += 1
            })
        }
        return res
    }

    useEffect(() => {
        let err = []
        Object.entries(form.customers).forEach((it) => {
            if (searchAddressForCustomer(it[0]) === 0) err.push(it[1].values.name)
        })
        setAlarmCustomerAddress(err)
    }, [form.customers, form.address, partnerAddress]); // eslint-disable-line

    useEffect(() => {
        if (!pause) {
            pause = true
            setTimeout(function () {
                pause = false
            }, 1);
            let customerAll = JSON.parse(JSON.stringify(form.address))
            Object.entries(customerAll).map((item, ind) => {
                if (item[1].values.auto_address)
                    item[1].error = validate(item[1].values, schemaAuto);
                else item[1].error = validate(item[1].values, schemaHand);
                item[1].error = item[1].error ? item[1].error : {}
                item[1].is_valid = Object.keys(item[1].error).length === 0 ? true : false
            })
            //customerAll.is_valid = validateAll > 0 ? false : true
            setForm(form => ({
                ...form,
                address: customerAll
            }));
        }

    }, [form.address]); // eslint-disable-line

    useEffect(() => {
        let arrMix = []
        let customerAll = JSON.parse(JSON.stringify(form.customers))
        Object.entries(customerAll).map((item) => {
            arrMix.push({
                id: item[0],
                name: item[1].values.name,
                new: true
            })
            /*if(form.partner.values.id){

            }*/
        })
        if (form.partner.values.id && partnerCustomers) {
            partnerCustomers.map((item) => {
                arrMix.push({
                    id: item.id,
                    name: item.name,
                    new: false
                })
            })
        }
        setMixCustomers(arrMix)
    }, [form.address]); // eslint-disable-line

    const handleChangeAccordionAddress = (event, newExpanded) => {
        setExpandAddress(newExpanded);
    };
    const handleChangeAccordion = (index) => (event, newExpanded) => {
        setForm(form => ({
            ...form,
            address: {
                ...form.address,
                [index]: {
                    ...form.address[index],
                    expanded: newExpanded
                }
            }
        }));
    };
    const handleDelAddress = (event, index) => {
        // console.log('index===========>', index)
        let tmp = JSON.parse(JSON.stringify(form.address))
        delete tmp[index]
        setForm(form => ({
            ...form,
            address: tmp
        }));
    };
    const handleAddAddress = (event) => {
        // let index = form.address.length
        let index = new Date().getTime();
        setForm(form => ({
            ...form,
            address: {
                ...form.address,
                [index]: iniAddress
            }
        }));
    };


    return (
        <Box
            display="grid"
            sx={{width: '100%'}}
            class="accordeon"
        >
            <h3>{translate(`Address for partner`)}:{' '}
                {form.partner.values.id ? form.partner.values.id.name : form.partner.values.name}</h3>
            {form.partner.values.id &&
                <>
                    <Box alignItems={"start"} mt={1}>
                        <GetArrayFieldFromResource
                            resource={'zCustomers'}
                            field={'name'}
                            id={form.partner.values.id.customers}
                        />
                    </Box>
                    {/*<Box alignItems={"start"} mt={1} display={'grid'}>
                        {partnerAddress && partnerAddress.map((item, index) => {
                            return (
                                <Typography variant="body" color={"textPrimary"} key={item.index}>
                                    {index + 1}. {item.address} ({item.name})
                                </Typography>
                            )
                        })
                        }
                    </Box>*/}
                    <Accordion
                        // expanded={expanded === 'panel1'}
                        expanded={expandAddress}
                        onChange={handleChangeAccordionAddress}
                    >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                          expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant="h6" color={"textPrimary"}>
                                {`${translate(`Old address`)}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box mt={1}
                                 style={{overflowX: "auto", overflowY: "hidden"}}
                                 display="grid" //Без этого прокрутка не работает
                            >
                                <PartnerAddress
                                    {...props}
                                    partnerAddress={partnerAddress}
                                    edit={true}
                                    mixCustomers={mixCustomers}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                </>
            }

            {alarmCustomerAddress.map((it, ind) => {
                return (
                    <Alert severity='error' key={ind}>
                        {translate("Need to specify an address for the counterparty")} <b>{it}</b>
                    </Alert>
                )
            })
            }

            {Object.entries(form.address).map((item, ind) => {
                let index = item[0]
                // let name = item[1].values.name
                let address = item[1].values.address
                let valid = item[1].is_valid
                return (
                    <Accordion
                        key={index}
                        // expanded={expanded === 'panel1'}
                        expanded={form.address[index].expanded}
                        onChange={handleChangeAccordion(index)}
                    >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                          expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant="h6" color={!valid ? "error" : "textPrimary"}>
                                {`${translate(`Address`)}`}: {address}
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'grid'} style={{padding: isSmall ? 0 : 16, width: '100%'}}>
                                {Object.entries(form.address).length > 0 &&
                                    <Box mt={-3} mb={-2} style={{width: '100%', textAlign: "right"}}>
                                        <Button
                                            style={{marginRight: 16, color: "red"}}
                                            //disabled={!isValidStep(activeStep)}
                                            variant="contained"
                                            color="error"
                                            onClick={(event) => handleDelAddress(event, index)}
                                            //className={classes.button}
                                        >
                                            {translate('Delete')}
                                        </Button>
                                    </Box>
                                }

                                <EditAddress
                                    {...props}
                                    processing={processing}
                                    setProcessing={setProcessing}
                                    index={index}
                                    mixCustomers={mixCustomers}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                )
            })
            }

            <Box>
                <Button
                    style={{width: '100%', color: "blue"}}
                    //disabled={!isValidStep(activeStep)}
                    variant="contained"
                    color="error"
                    onClick={(event) => handleAddAddress(event)}
                    //className={classes.button}
                >
                    {translate('Add address')}
                </Button>
            </Box>
        </Box>
    )
};