import React from "react";
// import { TextInput } from "react-admin";
import InputMask from "react-input-mask";
import {TextField} from "@material-ui/core";

/*const MaskedInput = (props) => (
  <InputMask mask="+380 (99) 999 99 99" {...props}
             size={"small"}
             // variant="outlined"
  >
    {(inputProps) =>
        <TextField {...props} {...inputProps}
            disableUnderline={false}
            type="tel"
        />}
  </InputMask>
);

const PhoneInput = (props) => (
  <TextInput {...props} InputProps={{ inputComponent: MaskedInput }} />
);*/
const PhoneInput = (props) => {

    const {
        marginTop=0,
        variant = "outlined",
        source, formData, formEdit, label,
        validate = null
    } = props

    const [start, setStart] = React.useState(false);
    const handleChangeText = (event) => {
        setStart(true)
        formEdit.change(source, event.target.value)
    };

    return (
        <InputMask
            style={{marginTop: marginTop && marginTop}}
            mask="+380 (99) 999-99-99"
            fullWidth
            label={label}
            value={formData[source]}
            //name={'phone'}
            onChange={(event) => handleChangeText(event)}
            size={"small"}
            variant={variant}
            //error={form.partner.error?.phone}
            //InputProps={{classes: {input: classes.input1}}}
        >
            {(inputProps) =>
                <TextField
                    {...inputProps}
                    variant="outlined"
                    type="tel"
                    // disableUnderline
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    error={validate && start
                        ? !formData[source] || formData[source].indexOf('_') >= 0
                        : false
                    }
                    //helperText={hasError('phone') ? formState.errors.phone[0] : null}
                />}
        </InputMask>
    )
};
export default PhoneInput;