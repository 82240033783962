import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate, TextInput,
    NumberInput, SelectInput, ReferenceInput, AutocompleteInput, useLocale, FormDataConsumer,
} from 'react-admin';
import {Box, useMediaQuery} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";

import {CustomersRelation} from "./CustomersRelation";
import {validateNameRec, validateReq} from "../../_constants/validateTable";
import {
    // choicesBusinessType,
    choicesContractorType,
    choicesHowShowQuantity, choicesNdsKey
} from "../../_constants/choices";
import FootherEdit from "../../_common/Headers/FootherEdit";

import {useForm} from "react-final-form";
import PhoneInput from "../../_common/PhoneInput";

/*const BlockEdit2 = props => {
    const translate = useTranslate();
    const {isSmall} = props

    return (
        <Box p="1em" fullWidth>
            <Box style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        validate={validateReq}
                        fullWidth
                        source={'delivery_address'}
                        label={translate('Delivery address')}
                    />
                </Box>
                <Box flex={1}>

                </Box>
            </Box>
        </Box>
    )
}*/
const BlockEdit = props => {
    const translate = useTranslate();
    const {isSmall} = props
    const locale = useLocale();
    const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        validate={validateReq}
                        fullWidth
                        source={'name'}
                        label={translate('Customer name')}
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'official_name.uk'}
                        label={translate('Official name')}
                    />
                    {/*<MyTranslatableInputs
                        //validate={validateReq}
                        fullWidth
                        source={'official_name'}
                        label={translate('Official name')}
                    />*/}
                </Box>
            </Box>
            <Box mt={'-1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'item_code_in_accounting_system'}
                        label={translate('Code from the accounting system')}
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'client_code'}
                        label={translate('Client code')}
                    />
                </Box>
            </Box>

            <Box mt={'2em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Main warehouse')}
                        source="base_warehouse_main_supplier"
                        reference="dSuppliersWarehouses"
                    >
                        <AutocompleteInput optionText={`name[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    {/*<TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'phone'}
                        label={translate('Phone')}
                    />*/}
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <PhoneInput
                                    marginTop={8}
                                    source={`phone`}
                                    label={translate('Phone')}
                                    variant={"outlined"}
                                    formData={formData}
                                    formEdit={formEdit}
                                    // validate={validateNameRec}
                                />
                            )
                        }}
                    </FormDataConsumer>
                </Box>
            </Box>
            <Box mt={'-1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        multiline={true}
                        variant={"outlined"}
                        fullWidth
                        source={'billing_address.uk'}
                        label={translate('Physical address')}
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        multiline={true}
                        variant={"outlined"}
                        fullWidth
                        source={'address.uk'}
                        label={translate('Legal address')}
                    />
                </Box>
            </Box>
            <Box mt={'-1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"} display="flex">
                    <Box flex={1} mr={"1em"}>
                        <TextInput
                            variant={"outlined"}
                            fullWidth
                            source={'tax_number'}
                            label={translate('Tax number')}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            fullWidth
                            source={'edrpou'}
                            label={translate('EDRPOU')}
                        />
                    </Box>
                </Box>
                <Box flex={1} display="flex">
                    <Box flex={1} mr={"1em"}>
                        <TextInput
                            variant={"outlined"}
                            fullWidth
                            source={'vat_number'}
                            label={translate('VAT identification number')}
                        />
                    </Box>
                    <Box flex={1}>
                        <SelectInput
                            variant={"outlined"}
                            source={'nds_key'}
                            choices={choicesNdsKey[locale]}
                            label={translate('VAT payer')} resettable fullWidth
                        />
                    </Box>
                </Box>
            </Box>

            <Box mt={'2em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"} display="flex">
                    <Box flex={1} mr={"1em"}>
                        <TextInput
                            variant={"outlined"}
                            fullWidth
                            source={'credit_limit_sum'}
                            label={translate('Credit limit amount')}
                        />
                    </Box>
                    <Box flex={1}>
                        <ReferenceInput
                            label={translate('Credit limit currency')}
                            source="credit_limit_currency"
                            reference="dCurrencies"
                        >
                            <AutocompleteInput optionText={`simbol[${locale}]`} resettable
                                               variant={"outlined"} fullWidth/>
                        </ReferenceInput>
                    </Box>
                </Box>
                <Box flex={1} display="flex">
                    <Box flex={1} mr={"1em"}>
                        <NumberInput
                            variant={"outlined"}
                            fullWidth
                            source={'credit_limit_days'}
                            label={translate('Credit limit term (days)')}
                        />
                    </Box>
                    <Box flex={1}>
                        <NumberInput
                            variant={"outlined"}
                            fullWidth
                            source={'retail_margin'}
                            label={translate('Retail margin')}
                        />
                    </Box>
                </Box>
            </Box>
            <Box mt={'-1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <SelectInput
                        variant={"outlined"}
                        source={'contractor_type'}
                        choices={choicesContractorType[locale]}
                        label={translate('Contractor type')} resettable fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Business type')}
                        source="business_type"
                        reference="dBusinessType"
                    >
                        <AutocompleteInput optionText={`name[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                    {/*<SelectInput
                        variant={"outlined"}
                        source={'business_type'}
                        choices={choicesBusinessType[locale]}
                        label={translate('Business type')} resettable fullWidth
                    />*/}
                </Box>
            </Box>
            <Box mt={'-1em'} style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <SelectInput
                        validate={validateReq}
                        variant={"outlined"}
                        source={'how_show_quantity'}
                        choices={choicesHowShowQuantity[locale]}
                        initialValue={'INTERVAL>10'}
                        label={translate('How to show quantity')} resettable fullWidth
                    />
                </Box>
            </Box>

        </Box>
    )
}
export const CustomersEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record} = props;
    const {id, uuid} = record || {};
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });

    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{
                    source: localStorage.getItem('username'),
                    price_type: 62,
                    discount_source: "CUSTOMER_DISCOUNT",
                    discount: 0,
                }}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        related_id={id}
                        isSmall={isSmall}
                        //promptData={promptData}
                    />
                </FormTab>
                {/*<FormTab label={translate('Other')}>
                    <BlockEdit2
                        {...props}
                        related_id={id}
                        isSmall={isSmall}
                        //promptData={promptData}
                    />
                </FormTab>*/}
            </TabbedForm>

            <Box p="1em" fullWidth>
                {id &&
                    <CustomersRelation
                        {...props}
                        related_id={id}
                        uuid={uuid}
                    />
                }
            </Box>

            {id && <FootherEdit source={record.source}/>}

        </Box>
    )
};

export default CustomersEditCreate
