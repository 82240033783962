import React from 'react';
import { TextField } from 'react-admin';

export const formatPhoneNumber = (phone) => {
    if (!phone) return '';

    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }

    return phone;
};

const PhoneField = (props) => {
    const { source, record = {} } = props;
    const value = record[source];
    const formattedPhone = formatPhoneNumber(value);

    return <TextField {...props} source={source} record={{ [source]: formattedPhone }} />;
};

export default PhoneField;