import * as React from 'react';
import {
    Box,
    useMediaQuery
} from "@material-ui/core";

import {
    useLocale,
    useTranslate
} from "react-admin";
import Asynchronous2 from "../../_common/AsynchronousRequests2";
import {getRequestOverpass} from "../../_constants/requestOverpass";
import AsynchronousAdr from "./AsynchronousRequestsAdr";
import {useEffect, useState} from "react";

export const SelectAddress = props => {
    const {
        setProcessing,
        setForm,
        areas,
        handleChangeSel,
        handleChangeText,
        form,
        // setForm,
        // iniCustomers,
        index,
        handleSetForm,
    } = props

    const translate = useTranslate();
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm'); //xs  sm
    });
    const formEl = form.address[index]

    const [start, setStart] = useState(true);
    const [area, setArea] = useState(form.address[index].values.area);
    const [region, setRegion] = useState(form.address[index].values.region);
    const [point, setPoint] = useState(form.address[index].values.point);
    const [street, setStreet] = useState(form.address[index].values.street);

    useEffect(() => {
        setTimeout(function () {
            setStart(false)
        }, 1000);
    }, [start]); // eslint-disable-line

    useEffect(() => {
        handleSetForm('address', index, 'area', area)
        console.log(start, 'formEl.values.area==========>', formEl.values.area)
        if (!start) {
            setRegion(null)
            setPoint(null)
            setStreet(null)
            handleSetForm('address', index, 'region', null)
            handleSetForm('address', index, 'point', null)
            handleSetForm('address', index, 'street', null)
        }
    }, [area]); // eslint-disable-line

    useEffect(() => {
        handleSetForm('address', index, 'region', region)
        console.log('formEl.values.region==========>', formEl.values.region)
        if (!start) {
            setPoint(null)
            setStreet(null)
            handleSetForm('address', index, 'point', null)
            handleSetForm('address', index, 'street', null)
        }
    }, [region]); // eslint-disable-line
    useEffect(() => {
        handleSetForm('address', index, 'point', point)
        console.log('formEl.values.point==========>', formEl.values.point)
        if (!start) {
            setStreet(null)
            handleSetForm('address', index, 'street', null)
        }
    }, [point]); // eslint-disable-line

    useEffect(() => {
        console.log('formEl.values.street==========>', formEl.values.street)
        handleSetForm('address', index, 'street', street)
    }, [street]); // eslint-disable-line

    return (
        <Box mt={2} display={isSmall ? "grid" : 'flex'} alignItems={"top"}>
            <Box flex={1} mr={!isSmall  && "1em"} mb={isSmall  && "1em"}>
                <Asynchronous2
                    label={translate('Area')}
                    value={area}
                    setValue={(value) => setArea(value)}
                    // domain={'https://overpass-api.de/'}
                    // url={`api/interpreter?data=${getRequestOverpass('getAreas', '', '', '')}`}
                    choices={areas}
                    // outputField='id'
                    //searchField={'search'}
                    getOptionLabel={(option) => `${option.name}`}
                    nameForFilter={'name'}
                    error={formEl.error?.area}
                />
            </Box>
            <Box flex={1} mr={!isSmall  && "1em"} mb={isSmall  && "1em"}>
                <AsynchronousAdr
                    label={translate('Region')}
                    value={region}
                    setValue={(value) => setRegion(value)}
                    domain={'https://overpass-api.de/'}
                    url={area?.iso
                        ?
                        `api/interpreter?data=${getRequestOverpass('getRegions', area.iso, '', '')}`
                        : ''
                    }
                    // choices={formEl.regions}
                    // outputField='id'
                    //searchField={'search'}
                    getOptionLabel={(option) => `${option.name}`}
                    typeReq={'getRegions'}
                    error={formEl.error?.region}
                />
            </Box>
            <Box flex={1} mr={!isSmall  && "1em"} mb={isSmall  && "1em"}>
                <AsynchronousAdr
                    label={translate('Point')}
                    value={point}
                    setValue={(value) => setPoint(value)}
                    domain={'https://overpass-api.de/'}
                    url={region?.name_ori
                        ?
                        `api/interpreter?data=${getRequestOverpass('getPoints', '', region.name_ori, '')}`
                        : area?.iso
                            ? `api/interpreter?data=${getRequestOverpass('getPointsFromArea', area.iso, '', '')}`
                            : ''
                    }
                    // choices={formEl.regions}
                    // outputField='id'
                    //searchField={'search'}
                    getOptionLabel={(option) => `${option.name}`}
                    typeReq={region?.name_ori ? 'getPoints' : 'getPointsFromArea'}
                    error={formEl.error?.point}
                />
            </Box>
            <Box flex={1}>
                <AsynchronousAdr
                    label={translate('Street')}
                    value={street}
                    setValue={(value) => setStreet(value)}
                    domain={'https://overpass-api.de/'}
                    url={point?.name_ori
                        ?
                        `api/interpreter?data=${getRequestOverpass('getStreets', '', point.name_ori, '')}`
                        : ''
                    }
                    // choices={formEl.regions}
                    // outputField='id'
                    //searchField={'search'}
                    getOptionLabel={(option) => `${option.name}`}
                    typeReq={'getStreets'}
                    error={formEl.error?.street}
                />
            </Box>
        </Box>
    )
}
