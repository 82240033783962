import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput, useTranslate,
} from 'react-admin';

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    FilterDataAside,
} from "../../_common/CompReact";
import {validateReq} from "../../_constants/validateTable";
import {useMemo} from "react";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    // dt.push(<TextInput label="Search" source="q" alwaysOn/>)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!"/>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <FilterDataAside fields={'created_at'} name={'create'}/>
                <FilterDataAside fields={'updated_at'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

const ListEditForm = (props) => {
    // const {classes, locale} = props;
    const {classes} = props;
    const translate = useTranslate();
    return (
        <RowForm {...props}>
            <NumberField source="id" headerClassName={classes.headerShortWidth}/>
            <ReferenceInput source="product_id" reference="product_z" label={translate('Product')}
                            validate={validateReq}>
                <AutocompleteInput
                    resettable
                    // optionText="name"
                    optionText={useMemo(() => {
                            // return ((record) => `${record?.article} - ${record?.name}`)
                            return ((record) => `${record?.article} - ${record?.name && record?.article ? record.name.replace(`(${record.article})`, '') : record?.name} (${record?.article_clean})`)
                        }, [])}
                    fullWidth
                />
            </ReferenceInput>
            <ReferenceInput source="discount_property_id" reference="discountProperty" label={translate('Discount property')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText="name" fullWidth/>
            </ReferenceInput>
            <ReferenceInput source="discount_property_value_id" reference="discountPropertyValue" label={translate('Discount property value')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText="name" fullWidth/>
            </ReferenceInput>
            <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>
            <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>
        </RowForm>
    )
};

export const ProductProperty = props => {
    const classes = useMyStyles();
    const {resource} = props;
    const translate = useTranslate();
    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${".list.columns"}`}
                select={false}
                create={true}
                filter={true}
            />}
            // is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            <EditableDatagrid
                classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                //noDelete={true}
                // initialRowMode="edit"
                rowClick="edit"
                mutationMode="pessimistic" //Подтвердить удаление
                undoable
                createForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={false}/>}
                editForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={true}/>}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceField source="product_id" reference="product_z" label={translate('Product')}
                                link={true}
                                sortBy={'product_z:name'}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="discount_property_id" reference="discountProperty" label={translate('Discount property')}
                                link={true}
                                sortBy={'discountProperty:name'}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="discount_property_value_id" reference="discountPropertyValue" label={translate('Discount property value')}
                                link={true}
                                sortBy={'discountPropertyValue:name'}>
                    <TextField source="name"/>
                </ReferenceField>

                <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                               locale={'us'} cellClassName={classes.date_st}/>
                <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                               locale={'us'} cellClassName={classes.date_st}/>
            </EditableDatagrid>
        </ListComponentAdmin>
    )
};