// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../../utils/keycloak";
import get from 'lodash/get';

export default function AsynchronousAdr(props) {
    const {
        multiple = false,
        error = false,
        value = null,
        setValue,
        //defaultValue,
        choices = null,
        url = '',
        getOptionLabel,
        label = '---',
        outputField = '',
        searchField = 'search',
        domain = apiPathConfig.getApiUrl(),
        typeReq,
    } = props

    const [open, setOpen] = React.useState(false);
    const [valueTyping, setValueTyping] = React.useState('');
    const [options, setOptions] = React.useState(choices ? choices : []);
    const [loading, setLoading] = React.useState(false);
    // const loading = open && options.length === 0;


    // const domain = apiPathConfig.getApiUrl()
    const fetchQuery = `${domain}${url}`

    const handleChange = (event) => {
        setValueTyping(event.target.value)
        // debugger
    }
    const handleChangeA = (event, newInputValue) => {
        //console.log('newInputValue======>', newInputValue)
        let res = null
        if (newInputValue) {
            if (outputField) res = get(newInputValue, outputField, null)
            else res = newInputValue
        }
        setValue(res)
        /*if (newInputValue && newInputValue[outputField] !== undefined) setValue(newInputValue[outputField])
        else setValue(null)*/
    }

    React.useEffect(() => {
        if (choices) setOptions(choices)
    }, [choices]); // eslint-disable-line

    function buildResult(dt, type) {
        let arr = []
        dt.forEach(it => {
            // if (it.tags?.highway !== "trunk")
            let name = it.tags.old_name ? `${it.tags.name}(${it.tags.old_name})` : it.tags.name
            // name = it.lat ? name : `${name} (-)`
            if (arr.find(x => x.tags.name === it.tags.name)) {

            } else {
                arr.push({
                    id: it.id,
                    lat: it?.lat,
                    lon: it?.lon,
                    name: name,
                    name_ori: it.tags.name,
                    tags: it.tags
                    //nameEn: it.tags['name:en']
                })
            }
        })
        switch (type) {
            case 'getRegions':
                arr = arr.filter(x => x?.tags?.katotth)
                break;
            case 'getPointsFromArea':
            case 'getPoints':
                arr = arr.filter(x => x.lat && x.tags?.type !== "boundary" && x.tags?.place !== "locality")
                break;
            case 'getStreets':
                /*arr = arr.filter(x =>
                    x.tags?.highway !== "trunk" &&
                    x.tags?.highway !== "steps" &&
                    x.tags?.highway !== "footway" &&
                    x.tags?.highway !== "path")*/
                break;
            default:
                break;
        }
        /*arr.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        })*/
        arr.sort(function (a, b) {
            let a_name = a.name && a.name.length>0 ? a.name.toLowerCase() : a.name
            let b_name = b.name && b.name.length>0 ? b.name.toLowerCase() : b.name
            if (a_name < b_name) return -1;
            if (a_name > b_name) return 1;
            return 0;
        })
        return arr
    }

    React.useEffect(() => {
        if (choices === null) {
            if (url) {
                (async () => {
                    setLoading(true)
                    const response = await httpClient(`${fetchQuery}`);
                    // await sleep(1e3); // For demo purposes.
                    const res = response.json;
                    const results = res.elements
                    // debugger
                    const res2 = Object.keys(results).map((key) => results[key])
                    setOptions(buildResult(res2, typeReq))
                    setLoading(false)
                })();
            } else setOptions([])
        }
    }, [setLoading, fetchQuery, valueTyping, searchField]);

     // console.log(typeReq,'RESPONSE====!!!=====>', options)

    // React.useEffect(() => {
    //     if (!open) {
    //         setOptions([]);
    //     }
    // }, [open]);

    return (
        <Autocomplete
            multiple={multiple}
            value={value}
            //defaultValue={defaultValue}
            id={`${url}-id-autocomplete`}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            // getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={getOptionLabel}
            options={options}
            /*filterOptions={(option, state) => {//Берет, все что пришло, не применяя фильтр
                return option
            }}*/
            onChange={handleChangeA}
            // onSelect={handleChangeA}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    value={valueTyping}
                    onChange={handleChange}
                    onBlur={(event) => {
                        setValueTyping('')
                    }}
                    size={"small"}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        //style: {padding: "0px"},
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}