import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    TextInput,
    useTranslate,
    AutocompleteInput, ReferenceInput,
    useLocale, BooleanInput, SelectInput, useGetList,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp, DateInputComp, ImageInputComp, NumberInputFloatComp} from "../../_common/CompReact";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {choicesPromotionType} from "../../_constants/choices";
import {validateReq} from "../../_constants/validateTable";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";
import {useEffect, useState} from "react";
import {getPromptForTable} from "../../_common/CompFormat/utils";
import {BaseTab} from "./BaseTab";
import {localesConfig} from "../../utils/config";

//import {validateReq} from "../utils/components/validateTable";

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";

const BlockEditImg = props => {
    const translate = useTranslate();
    //const {related_id} = props
    //const locale = useLocale();
    // const formEdit = useForm();
    return (
        <Box p="1em" fullWidth>
            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'} mb={'-3em'}>
                        <NumberInputFloatComp
                            defaultValue={2500}
                            variant={"outlined"}
                            fullWidth
                            source="banner_desktop_resolution"
                            label={translate('Banner desktop resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'banner_desktop'}
                        // label={`${'New'} ${'image'}`}
                        label={'Banner Desktop'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Desktop</b> here (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'} mb={'-3em'}>
                        <NumberInputFloatComp
                            defaultValue={2500}
                            variant={"outlined"}
                            fullWidth
                            source="banner_handset_resolution"
                            label={translate('Banner handset resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'banner_handset'}
                        // label={`${'New'} ${'image'}`}
                        label={'Banner Handset'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Handset</b> here (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'} mb={'-3em'}>
                        <NumberInputFloatComp
                            defaultValue={2500}
                            variant={"outlined"}
                            fullWidth
                            source="banner_tablet_resolution"
                            label={translate('Banner tablet resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'banner_tablet'}
                        // label={`${'New'} ${'image'}`}
                        label={'Banner Tablet'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Tablet</b> here (500kb max)</p>}
                    />
                </Box>
            </Box>

            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'} mb={'-3em'}>
                        <NumberInputFloatComp
                            defaultValue={2500}
                            variant={"outlined"}
                            fullWidth
                            source="horizontal_banner_resolution"
                            label={translate('Horizontal banner resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'horizontal_banner'}
                        // label={`${'New'} ${'image'}`}
                        label={'Horizontal Banner'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Horizontal Banner</b> here (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'} mb={'-3em'}>
                        <NumberInputFloatComp
                            defaultValue={2500}
                            variant={"outlined"}
                            fullWidth
                            source="vertical_banner_resolution"
                            label={translate('Vertical banner resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'vertical_banner'}
                        // label={`${'New'} ${'image'}`}
                        label={'Vertical Banner'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Vertical Banner</b> here (500kb max)</p>}
                    />
                </Box>

                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>

                </Box>
            </Box>

        </Box>
    )
}

const BlockEdit = props => {
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();
    const {promptData} = props;

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                        source={'active'} label={translate('Active')}
                        defaultValue={false}
                        //fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        source="sort" label={translate('Sort')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                </Box>
                <Box flex={1}>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        validate={validateReq}
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
                <Box flex={1} onChange={(e) => {
                    alert('privet')
                }}>
                    <SelectInput
                        variant={"outlined"}
                        source={'promotion_type'} choices={choicesPromotionType}
                        label={translate('Promotion type')} validate={validateReq} fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={2} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Badge')}
                        source={'badge'}
                        reference={'dPromotionBadge'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={2} display={'flex'}>
                    <Box flex={1} mr={"1em"}>
                        <DateInputComp
                            validate={validateReq}
                            setingTime={'T00:00:00.000Z'}
                            //time={true}
                            source={"date_from"} label={translate("Date From")}
                            variant={"outlined"} fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <DateInputComp
                            validate={validateReq}
                            setingTime={'T00:00:00.000Z'}
                            // time={true}
                            source={"date_to"} label={translate("Date To")}
                            variant={"outlined"} fullWidth
                        />
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={2} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Product Gift')}
                        source={'product_gift'}
                        reference={'dProducts'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={2} display={'flex'}>
                    <Box flex={1} mr={"1em"}>
                        <NumberInputFloatComp
                            source="product_gift_price" label={translate('Fix price')}
                            variant={"outlined"}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <ReferenceInput
                            label={translate('Currency')}
                            source={'product_gift_price_currency'}
                            reference={'dCurrencies'}
                            variant={"outlined"}
                            //filter={{country: id}}
                        >
                            <AutocompleteInput optionText={`abbr`} resettable fullWidth/>
                        </ReferenceInput>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'url'}
                        label={translate('URL')}
                    />
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <MyTranslatableInputs
                        validate={validateReq}
                        prompt={promptData.find(x => x?.field === 'description')}
                        fullWidth
                        source={'description'}
                        label={translate('Description')}
                    />
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"} mt={'-4em'}>
                <Box flex={1}>
                    <MyTranslatableInputsRich
                        prompt={promptData.find(x => x?.field === 'html_text')}
                        fullWidth
                        source={'formatted_description'}
                        label={translate('Formatted Description')}
                    />
                </Box>
            </Box>

        </Box>
    )
}
export const PromotionEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record, resource} = props;
    const {id} = record || {};
    const [promptData, setPromptData] = useState([]);


    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            setPromptData(dt)
            //console.log(resource, 'dt=============>',dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line


    const validateForm = (values) => {
        const errors = {};
        // console.log('VALID====>', values.description)
        if (values.description && !values.description[localesConfig.defaultLocale])
            errors.description = "Поле обязательно!";
        if (values.name && !values.name[localesConfig.defaultLocale])
            errors.description = "Поле обязательно!";
        return errors;
    };

    return (
        <Box>
            <TabbedForm
                {...props}
                validate={validateForm}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                        promptData={promptData}
                    />
                </FormTab>
                <FormTab label={translate('Media')}>
                    <BlockEditImg
                        {...props}
                        related_id={id}
                        //promptData={promptData}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box p="2em">
                    <h3>{translate('Related table')}</h3>
                    <BaseTab {...props} related_id={id}/>
                </Box>
            }

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default PromotionEditCreate
