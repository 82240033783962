import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    // TextInput,
    useTranslate,
    // ReferenceInput,
    // AutocompleteInput,
    //EmailField,
    ReferenceField,
} from 'react-admin';

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
import {CardContent, useMediaQuery} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    FilterDataAside,
    //NumberInputFloatComp,
    //UserInfo,
} from "../../_common/CompReact";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import {Box} from "@mui/material";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import CroppedLongField from "../../_common/CroppedLongField";
import MySimpleList from "../../_common/MySimpleList";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {
    // choicesBusinessType,
    choicesContractorType,
    choicesHowShowQuantity,
    choicesNdsKey,
} from "../../_constants/choices";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                {/*<DynamicFiltersChoices
                    reference={choicesHowShowQuantity}
                    fieldsSearch={'how_show_quantity'}
                    fieldsNameForFilter={'name'}
                    label={'how show quantity'}
                />*/}
                <FilterDataAside fields={'updated'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/


const ColumnsForList = (resource, locale, classes, isSmall) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        // name: <CustomerInfo label={translate('Name')} name={translate('name')} src={'avatar'} sortBy={'name'}/>,
        official_name: <TextField source={`official_name.uk`} label={translate('Official name')}/>,
        name: <TextField source={'name'} label={translate('Customer name')}/>,
        phone: <TextField source={`phone`} label={translate('Phone')}/>,
        item_code_in_accounting_system: <TextField source={'item_code_in_accounting_system'}
                                                   label={translate('Code from the accounting system')}/>,
        client_code: <TextField source={'client_code'} label={translate('Client code')}/>,
        uuid: <TextField source={'uuid'} label={translate('Uuid')}/>,
        // base_warehouse_main_supplier: <TextField source={'base_warehouse_main_supplier'} label={translate('Base warehouse main supplier')}/>,
        base_warehouse_main_supplier:
            <ReferenceField
                label={translate('Main warehouse')}
                source="base_warehouse_main_supplier"
                reference="dSuppliersWarehouses"
                link={false}
            >
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,
        billing_address: <TextField source={`billing_address.uk`} label={translate('Physical address')}/>,
        address: <TextField source={`address.uk`} label={translate('Legal address')}/>,
        credit_limit_days: <NumberField source={'credit_limit_days'} label={translate('Credit limit term (days)')}/>,
        credit_limit_sum:
            <Box flex={'flex'} label={translate('Credit limit amount')} style={{textAlign: "right"}}
                 sortBy={'credit_limit_sum'}>
                <TextField source={`credit_limit_sum`} label={translate('Credit limit amount')}/>{'\u00a0'}
                <ReferenceField source="credit_limit_currency" reference="dCurrencies" link={false}>
                    {/*<TextField source="iso3"/>*/}
                    <TextField source={`simbol[${locale}]`}/>
                </ReferenceField>
            </Box>,

        contractor_type: //<TextField source={'contractor_type'} label={translate('Contractor type')}/>,
            <SelectAndSaveChoices
                label={translate('Contractor type')}
                source="contractor_type"
                statuses={choicesContractorType[locale]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'10em'}
            />,
        business_type:
            <ReferenceField source="business_type" reference="dBusinessType" link={false} label={translate('Business type')}>
                {/*<TextField source="iso3"/>*/}
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,
        /*<SelectAndSaveChoices
                label={translate('Business type')}
                source="business_type"
                statuses={choicesBusinessType[locale]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'10em'}
            />,*/
        nds_key: //<TextField source={'nds_key'} label={translate('Nds key')}/>
            <SelectAndSaveChoices
                label={translate('VAT payer')}
                source="nds_key"
                statuses={choicesNdsKey[locale]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'3em'}
            />,
        edrpou: <TextField source={'edrpou'} label={translate('EDRPOU')}/>,
        tax_number: <NumberField source={'tax_number'} label={translate('Tax number')}/>,
        vat_number: <NumberField source={'vat_number'} label={translate('VAT identification number')}/>,
        how_show_quantity: //<TextField source={'how_show_quantity'} label={translate('How show quantity')}/>,
            <SelectAndSaveChoices
                label={translate('How to show quantity')}
                source="how_show_quantity"
                statuses={choicesHowShowQuantity[locale]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'10em'}
            />,
        retail_margin: <NumberField source={'retail_margin'} label={translate('Retail margin')}/>,

        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                locale={'us'} cellClassName={classes.date_st} showTime={true}/>,
        created: <DateFieldComp source="created" label={translate('Created')} textAlign={'center'}
                                locale={'us'} cellClassName={classes.date_st} showTime={true}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const CustomersList = ({permissions, ...props}) => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });
    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: [
            "base_warehouse_main_supplier", "billing_address", "contractor_type", "business_type",
            "nds_key", "edrpou", "tax_number", "vat_number", "how_show_quantity",
            "retail_margin", "updated", "created", "source"
        ],
    });

    // document.title = `iAM-Trade : Customers`
    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${"list.columns"}`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                //select={false}
                create={true}
                filter={true}
            />}
            is_bulkActionButtons={true}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
};