import * as React from 'react';
import {
    SelectInput,
    FilterLiveSearch,
    NumberField,
    TextField,
    useLocale,
    Datagrid,
    useTranslate,
    // ReferenceInput,
    // AutocompleteInput,
    EmailField,
    BooleanInput,
    TextInput,
    FormDataConsumer,
    PasswordInput,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList, ChipField, ReferenceInput, AutocompleteInput,
    useUpdate, useRecordContext,
} from 'react-admin';
// import {RowForm} from "@react-admin/ra-editable-datagrid";
import {useForm} from "react-final-form";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
import {CardContent, Typography, useMediaQuery} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    FilterDataAside,
    //NumberInputFloatComp,
    UserInfo,
} from "../../_common/CompReact";
// import {Box} from "@mui/material";
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import {
    choicesContractorType,
    choicesCreateFromDataRequest,
    choicesStatusRequest,
    getStatusById
} from "../../_constants/choices";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {Dialog} from "@mui/material";
import RowFormCustom from "../../_common/DependenciesComp/RowFormCustom";
import {Box, Grid} from "@mui/material";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {validateEmailReq, validateReq} from "../../_constants/validateTable";
import {canAccess2} from "../../utils/canAccess2";
// import {useEffect} from "react";
// import SaveIcon from '@mui/icons-material/Save';
// import PasswordIcon from '@mui/icons-material/Password';
import LockResetIcon from '@mui/icons-material/LockReset';
import {useEffect, useMemo, useState} from "react";
import CroppedLongField from "../../_common/CroppedLongField";
import PhoneField, {formatPhoneNumber} from "../../_common/PhoneField";
import Asynchronous2 from "../../_common/AsynchronousRequests2";
import {
    GetArrayFieldFromResource,
    GetFieldFromChoice, getFieldFromResource,
    GetFieldFromResource
} from "../../pagesZenit/createPartner/Partner";
import Button from '@mui/material/Button';
import {Menu, MenuItem} from '@mui/material'//"@material-ui/core";

import {Link} from 'react-admin';
// import {stringify} from "query-string";
// import products from "../dProducts";
import moment from "moment/moment";
import windowWidth, {windowHeight} from "../../_common/WindowWidth";
import DynamicFiltersChoices from "../../_common/DynamicFiltersChoices";
import Alert from "@material-ui/lab/Alert";
import get from 'lodash/get';


const TopFilters = (resource, locale) => {
    const translate = useTranslate();
    let dt = [];
    /*const truncateText = (text, maxLength = 10) =>
        text.length > maxLength ? text.substring(0, maxLength) + "..." : text;*/

    dt.push(<SelectInput source="request_type" label={translate("Request type")}
                         choices={choicesCreateFromDataRequest[locale]} alwaysOn
                         emptyText={'All Types'} resettable
        //optionText={(choice) => truncateText(choice.name)}
                         style={{width: '250px'}}
    />);
    dt.push(<ReferenceInput source="partner"
                            label={translate("Partner")}
                            reference="partner"
                            resettable
                            style={{width: '250px'}}
                            alwaysOn>
        <AutocompleteInput optionText={`name`} resettable emptyText={'All'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="business_types__id"
                            label={translate("Business type")}
                            reference="dBusinessType"
                            resettable
                            style={{width: '250px'}}
                            alwaysOn>
        <AutocompleteInput optionText={`name_${locale}`} resettable emptyText={'All types'}/>
    </ReferenceInput>);
    dt.push(<SelectInput source="form_of_ownership" label={translate("Contractor type")}
                         choices={choicesContractorType[locale]} alwaysOn
                         emptyText={'All Types'} resettable
                         style={{width: '250px'}}
    />);
    dt.push(<ReferenceInput source="processed_by"
                            filter={{with_managers: 123}}
                            label={translate("Manager")}
                            reference="dUser"
                            resettable
                            style={{width: '250px'}}
                            alwaysOn>
        <AutocompleteInput
            // optionText={`manager.name`}
            optionText={useMemo(() => {
                return ((record) =>
                    `${record?.additional?.manager?.name ? record?.additional?.manager?.name : 'All'} (${record?.username ? record?.username : ''})`)
            }, [])}
            // optionText={(record) => `${record?.manager?.name ? record?.manager?.name : 'All'} (${record?.username ? record?.username : ''})`}
            resettable emptyText={'All'}
        />
    </ReferenceInput>);
    dt.push(<TextInput label={translate("Area")} source="region" resettable alwaysOn/>);
    return dt;
}

const UniversalAside = (props) => {
    const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <DynamicFiltersChoices
                    label={'Status'}
                    reference={choicesStatusRequest[locale]}
                    fieldsSearch={'request_status'}
                    fieldsNameForFilter={'name'}
                />
                {/*<TrueFalseFilters field={'processed'} label={'Processed'}/>*/}
                <FilterDataAside fields={'created'} name={'created'}/>
                <FilterDataAside fields={'updated'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

const transform = (data) => {
    if (data.request_status === 'OPEN') {
        data.processed_by = null
    }
    if (data.request_status === 'IN_PROCESS') {
        if (!data.processed_by) {
            let username_id = localStorage.getItem('username_id')
            data.processed_by = username_id ? parseInt(username_id) : null
        }
    }
    if (!data.processed_by) data.processed_by = null
    return data
}

const ListEditForm = (props) => {//https://overpass-turbo.eu/index.html
    //const classes = useMyStyles();
    const {record, is_save} = props;
    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm'); //xs  sm
    });

    const winHeight = windowHeight()
    /*useEffect(() => {
        if (isSmall) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            localStorage.getItem('ETradeGroups')
        }
    }, []);*/

    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    return (
        <Dialog
            //fullWidth={true}
            scroll={'body'}
            classes={{
                paper: 'modal_with_product_list',
            }}
            maxWidth={isSmall ? 'xs' : 'md'}

            // onClose={handleDialogCloseCart}
            open={true}
            sx={{
                "& .MuiDialog-paper": {
                    position: isSmall && "absolute",
                    top: isSmall && 0,
                    left: isSmall && 0,
                    margin: isSmall && '12px',
                    // maxHeight: isSmall ? '-webkit-fill-available' : 'auto',
                    maxHeight: isSmall ? winHeight - 32 : 'auto',
                },
            }}
        >
            <RowFormCustom
                {...props}
                isSmall={isSmall}
                is_save={is_save}
                // validate={validateUserCreation}
                style={{width: '100%'}}
                hasBulkActions={false}
                /*initialValues={{
                    username: '',
                    is_active: true,
                    is_superuser: false,
                    is_staff: false,
                    password: '',
                    additional2: {
                        password_save: record?.id ? false : true,
                        password: ''
                    }
                }}*/
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
                transform={transform}
            >
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return (
                            <ListEditForm2
                                {...props}
                                formData={formData}
                                record={record}
                            />
                        )
                    }}
                </FormDataConsumer>

            </RowFormCustom>
        </Dialog>
    )
}
const ListEditForm2 = (props) => {
    const {record} = props;
    const translate = useTranslate();
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm'); //xs  sm
    });

    const [userName, setUserName] = React.useState({
        id: null,
        name: ''
    });
    const [canEdit, setCanEdit] = React.useState(false);

    useEffect(() => {
        let username = localStorage.getItem('username')
        let username_id = localStorage.getItem('username_id')
        setUserName({
            id: username_id ? parseInt(username_id) : null,
            name: username
        })
        const canEdit = canAccess2({resource: `dRequest/processed_by`, action: 'edit'});
        setCanEdit(canEdit)
    }, []);

    // let tz = moment().utcOffset()
    // let dt = moment(record.updated).add(tz, 'minutes').format("D-MMM-YY, H:mm:ss")
    let dt = moment(record.updated).format("D-MMM-YY, H:mm:ss")
    return (
        <Box fullWidth style={{maxWidth: isSmall && 350}}>
            <h3>{translate(`Заявка клиента`)}</h3>
            <Box display={isSmall ? "grid" : "flex"} alignItems={"start"}>
                <Box flex={1}>
                    {record.request_status === 'CLOSED'
                        ?
                        <Alert severity={'info'} style={{marginBottom: '2em'}}>
                            {translate(`Application closed`)}
                        </Alert>
                        :
                        canEdit ||
                        (userName.id === record.processed_by) ||
                        (record.request_status === 'OPEN')
                            ?
                            <SelectInput source={'request_status'}
                                         choices={choicesStatusRequest[locale]} resettable variant={"outlined"}
                                         label={translate('Status')} fullWidth/>
                            :
                            <Box flex={1} mb={'1em'}>
                                <Typography variant="body" color="textPrimary">
                                    {translate("Status")}{': '}
                                    <b><GetFieldFromChoice
                                        id={record.request_status}
                                        field={'name'}
                                        choiceArray={choicesStatusRequest[locale]}
                                    /></b>
                                </Typography>
                            </Box>
                    }
                </Box>
                {canEdit && record.request_status !== 'CLOSED' &&
                    <Box flex={1} mt={isSmall && '-1em'} ml={!isSmall && '2em'}>
                        <ReferenceInput
                            label={translate('Manager')}
                            source={'processed_by'}
                            reference={'dUser'}
                            filter={{with_managers: 123}}
                        >
                            <AutocompleteInput
                                /*optionText={useMemo(() => {
                                    return ((record) => `${record ? record?.manager?.name : ''} (${record ? record?.username : ''})`)
                                }, [])}*/
                                optionText={(record) =>
                                    `${record?.additional?.manager?.name ? record?.additional?.manager?.name : ''} (${record ? record?.username : ''})`}
                                // optionText={`manager.name`}
                                resettable variant={"outlined"} fullWidth
                            />
                        </ReferenceInput>
                    </Box>
                }
            </Box>

            <Box display="grid" alignItems={"top"} mt={'-1em'} mb={'1em'}>
                {(!canEdit || record.request_status === 'CLOSED') &&
                    <Typography variant="body" color="textSecondary">
                        {translate('Manager')}: <b>
                        {record.additional?.manager?.name} {' / '}
                        <GetFieldFromResource
                            id={record.processed_by}
                            field={'username'}
                            resource={'dUser'}
                        />
                    </b>
                    </Typography>
                }
                <Typography variant="body" color="textSecondary">
                    {translate('Updated')}: <b>{dt}</b>
                </Typography>
            </Box>

            {userName.id === record.processed_by
                ?
                record.request_status === 'IN_PROCESS'
                    ?
                    <Box display="grid" alignItems={"top"}>
                        <Button
                            //disabled={record.processed}
                            size="small"
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={{
                                pathname: `/createPartner/?request=${record.id}`,
                            }}
                            sx={{display: 'inline-flex', alignItems: 'center'}}
                        >
                            {translate('Create client')}
                        </Button>
                    </Box>
                    : null
                :
                record.request_status === 'IN_PROCESS'
                    ?
                    <Alert severity={'error'}>
                        {translate(`Заявка на обработку подана не Вами (${userName.name})`)}
                    </Alert>
                    :
                    null
            }

            <Box display="grid" alignItems={"top"} style={{maxWidth: isSmall && 350}} mt={2}>
                <Typography variant="body" color="textPrimary">
                    {translate("Request type")}{': '}
                    <b><GetFieldFromChoice
                        id={record.request_type}
                        field={'name'}
                        choiceArray={choicesCreateFromDataRequest[locale]}
                    /></b>
                </Typography>
            </Box>
            <Box display="grid" alignItems={"top"} style={{maxWidth: isSmall && 350}}>
                <Typography variant="body" color="textSecondary">
                    {translate("Comment")}: <b>{record.additional_info}</b>
                </Typography>
            </Box>

            <Box display={isSmall ? "grid" : "flex"} alignItems={"start"} style={{maxWidth: isSmall && 350}}>
                <Box display="grid" alignItems={"start"} style={{maxWidth: isSmall && 350}}>
                    <Box mt={2}>
                        <Typography variant="body" color="textPrimary">
                            <b>{translate("Partner information")}</b>
                        </Typography>
                    </Box>
                    <Box display={"grid"} alignItems={"start"} mt={1}>
                        <Box flex={1} display="grid" alignItems={"start"} mr={1}>
                            <Typography variant="body" color="textSecondary">
                                {translate("Name")}: <b>{record.partner_name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Email")}: <b>{record.partner_email}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Phone")}: <b>{formatPhoneNumber(record.partner_phone)}</b>
                            </Typography>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body" color="textPrimary">
                            <b>{translate("Partner customers")}</b>
                        </Typography>
                    </Box>
                    <Box display={"grid"} alignItems={"start"} mt={1}>
                        <Box flex={1} display="grid" alignItems={"start"} mr={1}>
                            <Typography variant="body" color="textSecondary">
                                {translate("Last Name")}: <b>{record.customer_last_name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("First Name")}: <b>{record.customer_first_name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Middle name")}: <b>{record.customer_middle_name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Contractor type")}{': '}
                                <b><GetFieldFromChoice
                                    id={record.form_of_ownership}
                                    field={'name'}
                                    choiceArray={choicesContractorType[locale]}
                                /></b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Business type")}{': '}
                                <b><GetArrayFieldFromResource
                                    resource={'dBusinessType'}
                                    field={`name_${locale}`}
                                    id={record.business_types}
                                /></b>
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box display={"grid"} alignItems={"start"} style={{maxWidth: isSmall && 350}}>
                    <Box mt={2}>
                        <Typography variant="body" color="textPrimary">
                            <b>{translate("Address")}</b>
                        </Typography>
                    </Box>
                    <Box display={"grid"} alignItems={"start"} mt={1}>
                        <Box flex={1} display="grid" alignItems={"start"} mr={1}>
                            <Typography variant="body" color="textSecondary">
                                {translate("Area")}: <b>{record.region}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Region")}: <b>{record.district}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Point")}: <b>{record.locality}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Street")}: <b>{record.street}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("House")}: <b>{record.building}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Flat")}: <b>{record.apartment}</b>
                            </Typography>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Box>
    )

}
/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

const StatusSelectAndSaveChoices = (props) => {
    const {
        button = true,
        source, statuses, resource,
        showTextValue, disableCondition,
        width, edit = false, color = false,
        is_field_no_edit = false,
        circle = false,
        send_only_this_field = true,
        hasInvertValue = false
    } = props;

    const translate = useTranslate();

    const [userNameID, setUserNameID] = React.useState(null);
    const [canEdit, setCanEdit] = React.useState(false);
    const record = useRecordContext(props);

    useEffect(() => {
        let username_id = localStorage.getItem('username_id')
        setUserNameID(username_id ? parseInt(username_id) : null)
        setCanEdit(canAccess2({resource: `dRequest/processed_by`, action: 'edit'}))
    }, []);

    let editEl = edit;
    // const refresh = useRefresh();
    const [update, {loading, error}] = useUpdate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (editEl) setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        event.stopPropagation();
    };
    const onClick = (event, v) => {
        handleClose(event);
        let data = id
        if(!send_only_this_field){
            data = record
            if (v === 'OPEN') data.processed_by = null
            else data.processed_by = userNameID
        }
        console.log(data, '===!!!!!!======>', record)
        data[source] = v
        update(resource, id, data, record);
    }

    if (!record) return null;
    const {id} = record || {};
    const fieldValue = get(record, source, null)
    const status = hasInvertValue ? !fieldValue : fieldValue

    if (is_field_no_edit) {
        editEl = get(record, is_field_no_edit, null);
        editEl = editEl ? false : true
    }


    //if (editEl === false && status === null) return null;

    if (error) {
        return <p>ERROR</p>;
    }

    if(record.request_status === 'CLOSED')
        return <h5 style={{color: "green"}}>{translate('Application closed')}</h5>;
    if(!canEdit && record.request_status !== 'OPEN' && record.processed_by !== userNameID)
        return <h5 style={{color: "red"}}>{translate('Уже в работе')}</h5>;

    const result = getStatusById(status, statuses);
    const {id: idRes, name, img, bgColor = null} = result || {};
    // const {id: idRes, name, img, color} = result || {};
    return <div style={{cursor: "pointer", textAlign: '-webkit-center', padding: 8, width: width && width,}}
                onClick={handleClick}>
        {circle
            ?
            <Box
                //onClick={handleClick}
                title={name}
                style={{
                    width: 15, height: 15,
                    borderRadius: '50%',
                    //border: '1px solid black',
                    backgroundColor: status !== null
                        ? status
                            ? circle === 'inv' ? '#e88b7d' : '#a4e87d'
                            : circle === 'inv' ? '#a4e87d' : '#e88b7d'
                        : 'magenta'
                }}
            />
            :
            button
                ?
                <Button
                    style={{
                        backgroundColor: bgColor ? bgColor : '#eee',
                        cursor: "pointer",
                        color: color
                            ? idRes ? color : 'red'
                            : 'inherit',
                        width: width && width,
                        padding: '4px 4px 2px 4px',
                        fontSize: 12,
                        opacity: (edit && !editEl) ? 0.3 : 1,
                    }}
                    title={`${'id: '}${status} / ${'name: '}${name}`}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    // disabled={loading || (disableCondition && !disableCondition(record))}
                    disabled={loading || disableCondition}
                >
                    {showTextValue ? translate(name) : img}
                </Button>
                :
                <span
                    style={{borderRadius: 2, padding: 8, backgroundColor: bgColor && bgColor}}
                >{translate(name)}
                </span>
        }

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {statuses.map(v => {
                    if (
                        !v?.disabled &&
                        (
                            canEdit ||
                            record.request_status==='OPEN' ||
                            record.processed_by===userNameID ||
                            record.processed_by===null
                        )
                    )
                        return (
                            <MenuItem style={{color: v.color && v.color}}
                                      key={v.id}
                                      selected={v.id === record.request_status}
                                      onClick={(event) => onClick(event, v.id)}
                            >
                                {v.image} {translate(v.name)}
                            </MenuItem>
                        )
                }
            )}
        </Menu>
    </div>;
}
const ColumnsForList = (resource, classes, canEdit, locale) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        /*processed:
            <SelectAndSaveChoices
                label={translate("Обр.")}
                source="processed"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={canEdit}
                send_only_this_field={false}
                color={'green'}
                circle={'no inv'}
            />,*/
        processed:
            <StatusSelectAndSaveChoices
                // button={false}
                label={translate("Status")}
                source="request_status"
                statuses={choicesStatusRequest[locale]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'7em'}
                edit={canEdit}
                send_only_this_field={false}
                //color={'green'}
                // circle={'no inv'}
            />,
        partner_name: <TextField source={`partner_name`} label={translate('Partner name')}/>,
        partner:
            <ReferenceField
                reference="partner"
                source="partner"
                label={translate('Partner')}
                // style={{padding: '8px 0'}}
            >
                <TextField source={`name`}/>
            </ReferenceField>,
        processed_by:
            <ReferenceField
                link={false}
                reference="dUser"
                source="processed_by"
                label={`${translate('Manager')}`}
                // style={{padding: '8px 0'}}
            >
                <Box display={'grid'}>
                    <TextField source={`additional.manager.name`}/>
                    <TextField source={`username`}/>
                </Box>
            </ReferenceField>,
        request_type:
            <SelectAndSaveChoices
                button={false}
                label={translate('Request type')}
                source="request_type"
                statuses={choicesCreateFromDataRequest[locale]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={canEdit}
                width={'10em'}
            />,
        partner_email: <TextField source={`partner_email`} label={translate('Partner email')}/>,
        partner_phone: <PhoneField source={`partner_phone`} label={translate('Partner phone')}/>,

        customer_name:
            <Box label={translate('Customer name')}>
                <TextField source={`customer_last_name`}/>{' '}
                <TextField source={`customer_first_name`}/>{' '}
                <TextField source={`customer_middle_name`}/>
            </Box>,

        business_types:
            <ReferenceArrayField
                reference="dBusinessType"
                source="business_types"
                label={translate('Business type')}
                style={{padding: '8px 0'}}
            >
                <SingleFieldList>
                    <ChipField source={`name[${locale}]`}/>
                </SingleFieldList>
            </ReferenceArrayField>,

        form_of_ownership:
            <SelectAndSaveChoices
                label={translate('Contractor type')}
                source="form_of_ownership"
                statuses={choicesContractorType[locale]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={canEdit}
                width={'12em'}
            />,

        region: <TextField source={`region`} label={translate('Area')}/>,
        district: <TextField source={`district`} label={translate('Region')}/>,
        address:
            <Box label={translate('Address')}>
                <TextField source={`locality`}/>{', '}
                <TextField source={`street`}/>{', '}
                <TextField source={`building`}/>{' / '}
                <TextField source={`apartment`}/>
            </Box>,


        additional_info: <TextField source={`additional_info`} label={translate('Info')}/>,
        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
        created: <DateFieldComp source="created" label={translate('Create')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,

    }
    return columns
}
export const RequestList = props => {
    const classes = useMyStyles();
    const {resource} = props;
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm'); //xs  sm
    });

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();

    const canEdit = canAccess2({resource: `${resource}`, action: 'edit'});
    const canCreate = canAccess2({resource: `${resource}`, action: 'create'});
    const canDelete = canAccess2({resource: `${resource}`, action: 'delete'});

    const columns = useSelectedColumns({
        preferences: `${resource}.list.columns`,
        columns: ColumnsForList(resource, classes, canEdit, locale),
        omit: ["id"],
    });
    let winWidth = windowWidth()
    return (
        <div style={{maxWidth: winWidth - 16, padding: 8}}>
            <ListComponentAdmin
                {...props}
                style={{overflowX: isSmall && 'auto'}}
                //hasCreate={true}
                title={''}
                actions={<TableActions
                    preference={`${resource}${".list.columns"}`}
                    columns={ColumnsForList(resource, locale, classes)}
                    select={true}
                    create={false}
                    filter={true}
                />}
                //is_bulkActionButtons={false}
                filters={TopFilters(resource, locale)}
                sort={{field: 'id', order: 'DESC'}}
                aside={<UniversalAside locale={locale}/>}
            >
                {/*<DatagridComponentAdmin>*/}
                {(canEdit || canCreate)
                    ?
                    <MyEditableDatagridForReference
                        hasBulkActionsRowForm={false}
                        rowClick={canEdit ? "edit" : false}
                        classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                        noDelete={!canDelete}
                        mutationMode="pessimistic" //Подтвердить удаление
                        undoable
                        createForm={<ListEditForm submitOnEnter={false} is_save={canCreate}/>}
                        editForm={<ListEditForm submitOnEnter={false} is_save={canEdit}/>}
                        //??? isRowEditable={() => false} // Disable editing for all rows
                        //rowStyle={postRowStyle}
                    >
                        {columns}
                    </MyEditableDatagridForReference>
                    :
                    <Datagrid>
                        {columns}
                    </Datagrid>
                }
                {/*</DatagridComponentAdmin>*/}
            </ListComponentAdmin>
        </div>
    )
};