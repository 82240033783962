import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    NumberField, useTranslate, FormDataConsumer,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {useForm} from "react-final-form";
import {useEffect, useState} from "react";
import {canAccess2} from "../../utils/canAccess2";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const SelDiscountProp = (props) => {
    //const classes = useMyStyles();
    const {formData, setFilter2, filter2} = props;
    const translate = useTranslate();
    const [start, setStart] = useState(true);
    const formEdit = useForm();

    /*
        useEffect(() => {
            if (!start || !formData.id) {
                if (filter2 >= 0) {
                    if (formData.discount_property_id) {
                        setFilter2(formData.discount_property_id)
                    } else {
                        setFilter2(0);
                    }
                    formEdit.change('discount_property_value_id', null)
                } else setFilter2(0);
            }
            setStart(false)
        }, [formData.discount_property_id]);// eslint-disable-line
    */
    useEffect(() => {
        if (!start) {
            if (formData.discount_property_id) {
                setFilter2(formData.discount_property_id)
            } else {
                setFilter2(0);
            }
            formEdit.change('discount_property_value_id', null)
        }
        setStart(false)
    }, [formData.discount_property_id]);// eslint-disable-line

    return (
        <ReferenceInput source="discount_property_id" reference="discountProperty"
                        label={translate('Discount property')}
                        filter={{rule_type: 'customer'}}
                        validate={validateReq}
        >
            <AutocompleteInput resettable optionText="name" variant={"outlined"} fullWidth/>
        </ReferenceInput>
    )
}
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {customer_id, record} = props;
    const translate = useTranslate();
    const [filter2, setFilter2] = useState(record.id ? record.discount_property_id : 0);
    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    customer_id: customer_id,
                }}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return (
                            <SelDiscountProp formData={formData} setFilter2={setFilter2} filter2={filter2} {...props}/>
                        )
                    }}
                </FormDataConsumer>
                <ReferenceInput source="discount_property_value_id" reference="discountPropertyValue"
                                label={translate('Discount property value')}
                                filter={{discount_property_id: filter2}}
                                validate={validateReq}
                >
                    <AutocompleteInput resettable optionText="name" variant={"outlined"} fullWidth/>
                </ReferenceInput>

            </RowForm>


        </>
    )
};

const ColumnsForList = (resource, classes, canEdit) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        /*customer_id: <ReferenceField source="customer_id" reference="customer_z" label={translate('Customer')}
                                     link={true}
                                     sortBy={'customer_z:name'}>
            <TextField source="name"/>
        </ReferenceField>,*/
        discount_property_id: <ReferenceField source="discount_property_id" reference="discountProperty"
                                              label={translate('Discount property')}
                                              link={canEdit}
                                              sortBy={'discountProperty:name'}>
            <TextField source="name"/>
        </ReferenceField>,
        discount_property_value_id: <ReferenceField source="discount_property_value_id"
                                                    reference="discountPropertyValue"
                                                    label={translate('Discount property value')}
                                                    link={false}
                                                    sortBy={'discountPropertyValue:name'}>
            <TextField source="name"/>
        </ReferenceField>,

        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

const CustomerProperty = props => {
    // const {customer_id, record, isSave = true} = props;
    const {customer_id, record, resource: from_resource} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    // console.log('customer_id=======>', customer_id);
    const canEdit = canAccess2({resource: `${from_resource}`, action: 'edit'});

    const columns = useSelectedColumns({
        preferences: `${'customer_property'}list.columns`,
        columns: ColumnsForList('customer_property', classes, canEdit),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            {/*<Button
                    //disabled={customer_id !== customerFormId}
                    variant={"outlined"}
                    size="small"
                    color="primary"
                    //onClick={(event) => ExportExcel(event, invoice_id, record)}
                    style={{float: "right"}}
                >
                    Export to Excel
                </Button>
                <Button
                    //disabled={customer_id !== customerFormId}
                    size="small"
                    color="primary"
                    //onClick={(event) => handleAllBooking(event, invoice_id)}
                    style={{marginRight: 24}}
                >
                    Fill all bookings
                </Button>*/}
            {/*{total_amount ? <div style={{marginTop: 16}}/> : null}
            {customer_id !== customerFormId
                ? record.qty_of_bookings
                    ? <Alert severity='warning'>The current bookings do not belong to this customer</Alert>
                    : <Alert severity='warning'>For further work save the CUSTOMER field</Alert>
                : null
            }*/}
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Customer Property"
                reference="customer_property"
                target="customer_ori_id"
                filter={{customer_id: customer_id}}
                // hasBulkActions={false}
            >
                <MyEditableDatagridForReference
                    canEdit={canEdit}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} record={record}
                                              customer_id={customer_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} record={record}
                                            customer_id={customer_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CustomerProperty;