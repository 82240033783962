import * as React from 'react';
import {
    Box, Grid, TableCell, TableRow, Typography,
    useMediaQuery
} from "@material-ui/core";

import {
    useLocale,
    useTranslate
} from "react-admin";
import Button from "@material-ui/core/Button";
import {createPartner} from "../../_common/CompForApi/utilsAPI";
import {PreviewPartner} from "./PreviewPartner";
import {PreviewCustomers} from "./PreviewCustomers";
import {PreviewAddress} from "./PreviewAddress";
import Alert from "@material-ui/lab/Alert";
import {GetArrayFieldFromResource} from "./Partner";
import {useEffect} from "react";

export const SavePartner = props => {
    const {
        form,
        partnerAddress,
        resultSave,
        setResultSave,
        setActiveStep,
        requestData,
        setRequestData
    } = props

    const translate = useTranslate();
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });

    useEffect(() => {
        if(resultSave?.ok) setRequestData(null)
    }, [resultSave]);

    // const formEl = form.address[index]
    const handleCreateNewPartner = async (event) => {
        //window.location.reload()
        setActiveStep(0)
    }
    const handleCreatePartner = async (event) => {
        let data = {
            account_request_id: requestData ? requestData?.id : null,
            partner_id: form.partner.values.id ? form.partner.values.id.id : null,
            partner_data: form.partner.values.id
                ? null
                : {
                    name: form.partner.values.name,
                    phone: form.partner.values.phone,
                    // phone_clean: '',
                    email: form.partner.values.email,
                    manager: form.partner.values.manager ? form.partner.values.manager.id : null,
                    sales_representative_1: form.partner.values.sales_representative_1
                        ? form.partner.values.sales_representative_1.id
                        : null,
                    sales_representative_2: form.partner.values.sales_representative_2
                        ? form.partner.values.sales_representative_2.id
                        : null,
                    // customers: [],
                    accounting_phone: form.partner.values.accounting_phone,
                    accounting_email: form.partner.values.accounting_email,
                    warehouse_phone: form.partner.values.warehouse_phone,
                },
            customer_list: [],
            new_address_list: [],
            change_address_list: [],
        }
        //--------------Partner--------------------------
        //--------------Customers--------------------------
        let arr = []
        Object.entries(form.customers).map((it, ind) => {
            //data.customer_list
            let dt = {
                customer_data: {},
                customer_pricing_property: [],
                customer_cross_id: it[0],
                user_data: null,
                user_id: it[1].values.user_id
                    ? it[1].values.user_id.id
                    : null
            }
            if (it[1].values.user_id === null) {
                dt.user_data = {
                    username: it[1].values.user_email,
                    email: it[1].values.user_email,
                    first_name: '',
                    last_name: '',
                }
            }
            it[1].values.customer_pricing_property.forEach(it2 => {
                if (it2.value) {
                    dt.customer_pricing_property.push({
                        property_id: it2.id,
                        property_name: it2.name,
                        property_slug: it2.slug,
                        value_id: it2.value.id,
                        value_name: it2.value.name,
                    })
                }
            })
            dt.customer_data = {
                official_name: it[1].values.official_name,
                name: it[1].values.name,
                item_code_in_accounting_system: it[1].values.item_code_in_accounting_system,
                client_code: it[1].values.client_code,
                phone: it[1].values.phone,
                // phone2: it[1].values.phone2,
                // phone3: it[1].values.phone3,
                billing_address: it[1].values.billing_address,
                address: it[1].values.address,
                vat_number: it[1].values.vat_number,
                credit_limit_sum: it[1].values.credit_limit_sum
                    ? it[1].values.credit_limit_sum
                    : 0,
                credit_limit_days: it[1].values.credit_limit_days
                    ? it[1].values.credit_limit_days
                    : 0,
                retail_margin: it[1].values.retail_margin
                    ? it[1].values.retail_margin
                    : 0,
                tax_number: it[1].values.tax_number,
                edrpou: it[1].values.edrpou,
                base_warehouse_main_supplier: it[1].values.base_warehouse_main_supplier
                    ? it[1].values.base_warehouse_main_supplier.id
                    : null,
                credit_limit_currency: it[1].values.credit_limit_currency
                    ? it[1].values.credit_limit_currency.id
                    : null,
                nds_key: it[1].values.nds_key
                    ? it[1].values.nds_key.id
                    : null,
                contractor_type: it[1].values.contractor_type
                    ? it[1].values.contractor_type.id
                    : null,
                business_type: it[1].values.business_type
                    ? it[1].values.business_type.id
                    : null,
                how_show_quantity: it[1].values.how_show_quantity
                    ? it[1].values.how_show_quantity.id
                    : null,

            }

            arr.push(dt)
        })
        data.customer_list = arr

        //--------------Address--------------------------
        arr = []
        Object.entries(form.address).map((it, ind) => {
            let dt = {
                auto_address: it[1].values.auto_address, //?
                address: it[1].values.address,
                house: it[1].values.house,
                flat: it[1].values.flat,
                area: it[1].values.auto_address
                    ? it[1].values.area?.name ? it[1].values.area.name : ''
                    : it[1].values.area_hand,
                region: it[1].values.auto_address
                    ? it[1].values.region?.name ? it[1].values.region.name : ''
                    : it[1].values.region_hand,
                point: it[1].values.auto_address
                    ? it[1].values.point?.name ? it[1].values.point.name : ''
                    : it[1].values.point_hand,
                street: it[1].values.auto_address
                    ? it[1].values.street?.name ? it[1].values.street.name : ''
                    : it[1].values.street_hand,
                // area_hand: '',
                // region_hand: '',
                // point_hand: '',
                // street_hand: '',
                confirmed: it[1].values.confirmed,
                disabled: it[1].values.disabled,
                name: it[1].values.name,
                lat: it[1].values.lat,
                lon: it[1].values.lon,
                comment: it[1].values.comment,
                placement: it[1].values.placement,
                deliveries: [],
                customers: [],
                customer_cross_id_list: [],
            }
            it[1].values.deliveries.forEach(item => {
                dt.deliveries.push(item.id)
            })
            it[1].values.customers.forEach(item => {
                if (item.new) dt.customer_cross_id_list.push(item.id)
                else dt.customers.push(item.id)
            })
            arr.push(dt)
        })
        data.new_address_list = arr

        //--------------Partner Address--------------------------
        if (form.partner.values.id && partnerAddress) {
            partnerAddress.forEach((it, ind) => {
                if (it.customer_cross && it.customer_cross.length > 0) {
                    let ar = []
                    it.customer_cross.forEach(it2 => {
                        ar.push(it2.id)
                    })
                    data.change_address_list.push({
                        address_id: it.id,
                        customer_cross_id_list: ar
                    })
                }
            })
        }

        console.log('SAVE============>', data)
        setResultSave(null)
        let res = await createPartner(data, setResultSave)

    };

    // let partner = form.partner.values.id ? form.partner.values.id : form.partner.values
    return (
        <Box p={isSmall ? "1em 0" : "1em"} fullWidth display={'grid'}>

            <Box p={isSmall ? "1em 0" : "1em"} fullWidth>
                {resultSave?.ok
                    ? null
                    :
                    <>
                        <Box p={isSmall ? "1em 0" : "1em"} fullWidth display={'grid'} style={{borderBottom: '1px solid #aaa'}}>
                            <Typography variant="h6" color="textPrimary">
                                <b>{translate("Partner")}</b>
                            </Typography>
                            <PreviewPartner form={form}/>
                        </Box>

                        <Box p={isSmall ? "1em 0" : "1em"} fullWidth display={'grid'} style={{borderBottom: '1px solid #aaa'}}>
                            <Typography variant="h6" color="textPrimary">
                                <b>{translate("Customers")}</b>
                            </Typography>
                            {form?.partner?.values.id &&
                                <Grid item xs={12} p={1}>
                                    <GetArrayFieldFromResource
                                        resource={'zCustomers'}
                                        field={'name'}
                                        id={form.partner.values.id.customers}
                                    />
                                </Grid>
                            }
                            <Box style={{backgroundColor: '#c8e3b8', padding: 12, marginLeft: 16, marginRight: 16}}>
                                <h3 style={{margin: 0}}>{translate('New customers')}</h3>
                            </Box>
                            <PreviewCustomers form={form}/>
                        </Box>

                        <Box p={isSmall ? "1em 0" : "1em"} fullWidth display={'grid'}
                             style={{borderBottom: '1px solid #aaa'}}
                        >
                            <Typography variant="h6" color="textPrimary">
                                <b>{translate("Address")}</b>
                            </Typography>
                            <Box style={{overflowX: 'auto'}}>
                                <PreviewAddress
                                    form={form}
                                    partnerAddress={partnerAddress}
                                />
                            </Box>
                        </Box>
                    </>
                }

                <Box p={isSmall ? "1em 0" : "1em"} fullWidth display={'grid'}>
                    {resultSave?.error &&
                        <Alert severity={'error'}>
                            {resultSave.error}
                        </Alert>
                    }
                    {resultSave?.ok &&
                        <Alert severity={'info'}>
                            {translate('Client saved')}{'. '}
                            {resultSave?.partner_id &&
                                <a href={`/#/partner/${resultSave.partner_id}`}>
                                    {translate('Go to client')} (ID={resultSave.partner_id})
                                </a>
                            }
                        </Alert>
                    }
                </Box>
                <Box p={isSmall ? "1em 0" : "1em"} fullWidth display={'grid'}>
                    {resultSave?.ok
                        ?
                        <Button
                            //disabled={!isValidStep(activeStep)}
                            variant="contained"
                            color="primary"
                            onClick={handleCreateNewPartner}
                            // className={classes.button}
                        >
                            {translate('Create new client')}
                        </Button>
                        :
                        <Button
                            //disabled={!isValidStep(activeStep)}
                            variant="contained"
                            color="primary"
                            onClick={handleCreatePartner}
                            // className={classes.button}
                        >
                            {translate('Save clients data')}
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    )
}

